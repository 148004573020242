import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "../Modal";
import SkuModal from "../SkuTotalModal";
import { lineConverter } from "../helpers/skuToLineOp";
import * as spOrderStatsService from "../../services/subprintStats.service";
import { IoIosRefresh } from "react-icons/io";

class SpItemsDue extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      readyTmrw: null,
      ready: null,
      tmrwObj: {},
      printedObj: {}, 
      linesToday: {},
      linesTmrw: {}
    }
  }

  componentDidMount() {
    spOrderStatsService.getItemsDueToday().then(response => {
      //console.log(response);
      let numReady = response.dueNum
      let due = JSON.parse(response.due)
      let lineAmountsToday = this.calculateByLine(due.data);
      let skuAmountsToday = this.calculateBySku(due.data);
      //console.log(due);
      this.setState({ 
        ready: numReady,
        printedObj: due,
        linesToday: lineAmountsToday,
        skusToday: skuAmountsToday
      });

    }).catch(err => {
      console.log(err);
    })

    spOrderStatsService.getItemsDueTmrw().then(response => {
      //console.log(response);
      let numReady = response.dueNum
      let due = JSON.parse(response.due)
      let lineAmountsTmrw = this.calculateByLine(due.data);
      let skuAmountsTmrw = this.calculateBySku(due.data);
      //console.log(due);
      this.setState({ 
        readyTmrw: numReady,
        tmrwObj: due,
        linesTmrw: lineAmountsTmrw,
        skusTmrw: skuAmountsTmrw
      });

    }).catch(err => {
      console.log(err);
    })
  }

  calculateBySku = (rows) => {
    let skuObj = {};
    rows.map((row, index) => {
      let sku = row[2];
      if (skuObj[sku]) {
        skuObj[sku] += row[3];
      } else {
        skuObj[sku] = row[3];
      }
    })
    let keys = Object.keys(skuObj);
    let totals = [];
    for (let i=0; i < keys.length; i++) {
      let currKey = keys[i];
      let currAmount = skuObj[currKey];
      totals.push([currKey, currAmount]);
    }
    // console.log(totals);
    return totals;
  }

  calculateByLine = (rows) => {
    let lineObj = {};
    rows.map((row, index) => {
      let line = lineConverter[row[2]];
      if (lineObj[line]) {
        lineObj[line] += row[3];
      } else {
        lineObj[line] = row[3];
      }
    })
    //console.log(lineObj);
    return lineObj;
  }

  refresh = () => {
    //console.log("REFRESH");
    this.componentDidMount();
    return;
  }

  render() {
    return (      
      <div className="shadow-lg ml-0.5 mt-1 px-4 py-6 w-full bg-slate-700 dark:bg-gray-800 relative">
        <span className="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          Items Due Today <Modal data={this.state.printedObj} numCols={6} art={true} /> <SkuModal cols={['Sku', 'Total']} data={this.state.skusToday} />
        </span>
        <button onClick={this.refresh} className="float-right text-md text-white">
          <IoIosRefresh className="hover:text-green-700" />
        </button>
        <div className="flex items-end space-x-2 my-4">
          <p className="text-5xl text-white dark:text-white font-bold">
            {this.state.ready}
          </p>
        </div>
        <div className="flex">
          <p className="text-sm text-white dark:text-white font-bold">
            {`Mugs: ${this.state.linesToday['Mug'] ? this.state.linesToday['Mug'] : 0} 
            Posters: ${this.state.linesToday['Poster'] ? this.state.linesToday['Poster'] : 0} 
            Press: ${this.state.linesToday['Press'] ? this.state.linesToday['Press'] : 0}
            Other: ${this.state.ready - ((this.state.linesToday['Press'] ? this.state.linesToday['Press'] : 0) + (this.state.linesToday['Poster'] ? this.state.linesToday['Poster'] : 0) + (this.state.linesToday['Mug'] ? this.state.linesToday['Mug'] : 0)) }`}
          </p>
        </div>
        <hr className="mb-5" />
        <span className="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          Items Due Next Business Day <Modal data={this.state.tmrwObj} numCols={6} art={true} /> <SkuModal cols={['Sku', 'Total']} data={this.state.skusTmrw} />
        </span>
        <div className="flex items-end space-x-2 my-3">
          <p className="text-5xl text-white dark:text-white font-bold">
            {this.state.readyTmrw}
          </p>
        </div>
        <div className="flex">
          <p className="text-sm text-white dark:text-white font-bold">
          {`Mugs: ${this.state.linesTmrw['Mug'] ? this.state.linesTmrw['Mug'] : 0} 
            Posters: ${this.state.linesTmrw['Poster'] ? this.state.linesTmrw['Poster'] : 0} 
            Press: ${this.state.linesTmrw['Press'] ? this.state.linesTmrw['Press'] : 0}
            Other: ${this.state.readyTmrw - ((this.state.linesTmrw['Press'] ? this.state.linesTmrw['Press'] : 0) + (this.state.linesTmrw['Poster'] ? this.state.linesTmrw['Poster'] : 0) + (this.state.linesTmrw['Mug'] ? this.state.linesTmrw['Mug'] : 0)) }`}
          </p>
        </div>
      </div>
    )
  }
}

export default SpItemsDue;