import React, { useState } from 'react';
import { Navigate } from "react-router-dom";
import Nav from './Nav';
import Header from './Header';
import IpQueueCard from './ip/IpOrdersCard';
import IpItemsQueueCard from './ip/IpOrderItemsCard';
import IpOrdersDue from './ip/IpOrdersDue';
import IpItemsDue from './ip/IpItemsDue';
import IpOrdersShipped from './ip/IpOrdersShipped';
import IpItemsShipped from './ip/IpItemsShipped';
import IpLateOrders from './ip/IpLateOrders';
import IpSkuCard from './ip/IpSkuCard';
import IpCancelCard from './ip/IpCanceledOrders';
import IpShipErrors from './ip/IpShipErrors';
import IpOrderCycleCard from './ip/IpOrderCycle';
import IpInventory from './ip/IpInventory';
import MultiLineChart from './MultiLineChart';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Cookies from 'js-cookie';

class Iprint extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        comps: {
          "IpOrdersDue": [IpOrdersDue, null],
          "IpItemsDue": [IpItemsDue, null],
          "IpOrderCard": [IpQueueCard, null],
          "IpOrderItemsCard": [IpItemsQueueCard, null],
          "IpOrdersShipped": [IpOrdersShipped, null],
          "IpItemsShipped": [IpItemsShipped, null],
          "IpLateOrders": [IpLateOrders, null],
          "IpOrdersBySkuCard": [IpSkuCard, null],
          "IpOrdersCanceled": [IpCancelCard, null],
          "IpShippingErrors": [IpShipErrors, null],
          "IpOrderCycle": [IpOrderCycleCard, null],
          "LineChart_Orders_Day": [MultiLineChart, "Orders"],
          "LineChart_Orders_Month": [MultiLineChart, "Orders"],
          "IpInventory": [IpInventory, null]
        },
        navOptions: [ 
          
          {id: "ordersCanceledIP", name: "IpOrdersCanceled", comp: IpCancelCard, size: 2},
          {id: "shippingErrorsIp", name: "IpShippingErrors", comp: IpShipErrors, size: 2},
          {id: "orderCycleIp", name: "IpOrderCycle", comp: IpOrderCycleCard, size: 2},
          {id: "inventoryIp", name: "IpInventory", comp: IpInventory, size: 2},   
        ],
        chartOgOrder: [
          {id: "mlc orders month IP", name: "LineChart_Orders_Month", comp: MultiLineChart, type: "orders", title: "Orders", time: "month", system: "IP", size: 8},
          {id: "mlc orders day IP", name: "LineChart_Orders_Day", comp: MultiLineChart, type: "orders", title: "Orders", time: "day", system: "IP", size: 8},
        ],
        OgFirstRow: [
          {id: "ordersDueIP", name: "IpOrdersDue", comp: IpOrdersDue, size: 2},
          {id: "itemsDueIP", name: "IpItemsDue", comp: IpItemsDue, size: 2},
          {id: "ordersQueueIP", name: "IpOrderCard", comp: IpQueueCard, size: 2},
          {id: "orderItemsIP", name: "IpOrderItemsCard", comp: IpItemsQueueCard, size: 2},
        ],
        OgSecondRow: [
          {id: "ordersShippedIP", name: "IpOrdersShipped", comp: IpOrdersShipped, size: 2},
          {id: "itemsShippedIP", name: "IpItemsShipped", comp: IpItemsShipped, size: 2},
          {id: "lateOrdersIP", name: "IpLateOrders", comp: IpLateOrders, size: 2},
          {id: "ordersSkuIP", name: "IpOrdersBySkuCard", comp: IpSkuCard, size: 2},
           
        ]
      }
    }
  
    componentDidMount() {
      let currEmail = localStorage.getItem('UserEmail');
      let cookie = Cookies.get('token');
      if (!currEmail || !cookie) {
        this.setState({
          notLoggedIn: true,
        });
      }
      let currSettings = Cookies.get('cardSettingsIP');
      if (currSettings) {
        currSettings = JSON.parse(currSettings);
        let keys = Object.keys(currSettings)
        for (let i=0; i < keys.length; i++) {
          let currKey = keys[i];
          if ((currKey === 'OgFirstRow') || (currKey === 'OgSecondRow') || (currKey === 'chartOgOrder') || (currKey === 'navOptions')) {
            let currArr = currSettings[currKey];
            for (let j=0; j < currArr.length; j++) {
              let currObj = currArr[j];
              currObj['comp'] = this.state.comps[currObj['name']][0];
              currObj['title'] = this.state.comps[currObj['name']][1];
              currArr[j] = currObj;
            }
            currSettings[currKey] = currArr
          }
          
        } 
        this.setState({
          navOptions: currSettings.navOptions,
          OgFirstRow: currSettings.OgFirstRow,
          OgSecondRow: currSettings.OgSecondRow,
          chartOgOrder: currSettings.chartOgOrder,
        });
      }
    }
  
    handleOnDragEnd = (result) => {
      let stateClone;
      if ((result.destination.droppableId === "compOptions") || (result.destination.droppableId === "navOptions")) {
        console.log("Removing Card From Grid")
        if (result.source.droppableId === "compFirstRow") {
          stateClone = [...this.state.OgFirstRow];
        } else if (result.source.droppableId === "compSecondRow") {
          stateClone = [...this.state.OgSecondRow];
        } else if (result.source.droppableId === "compCharts") {
          stateClone = [...this.state.chartOgOrder];
        }
        if (!stateClone) {
          //console.log('Nothing')
          if ((result.source.droppableId === "compOptions") || (result.source.droppableId === "navOptions")) {
            stateClone = [...this.state.navOptions];
            const [reorderedItem] = stateClone.splice(result.source.index, 1);
            stateClone.splice(result.destination.index, 0, reorderedItem);
            this.setState({
              navOptions: stateClone,
            });
          }
          return;
        }
        const [reorderedItem] = stateClone.splice(result.source.index, 1);
        let optionsClone = [...this.state.navOptions];
        optionsClone.splice(optionsClone.length, 0, reorderedItem);
        //console.log(reorderedItem);
        //console.log(stateClone);
        if (result.source.droppableId === "compFirstRow") {
          this.setState({
            OgFirstRow: stateClone,
          });
        } else if (result.source.droppableId === "compSecondRow") {
          this.setState({
            OgSecondRow: stateClone,
          });
        } else if (result.source.droppableId === "compCharts") {
          this.setState({
            chartOgOrder: stateClone,
          });
        }
        this.setState({
          navOptions: optionsClone
        });
      } else if (result.destination.droppableId === "compFirstRow") {
        //console.log("Adding to the First Row");
        stateClone = [...this.state.OgFirstRow];
        if (result.source.droppableId === "compFirstRow") {
          //console.log("Same Row Base Case");
          const [reorderedItem] = stateClone.splice(result.source.index, 1);
          stateClone.splice(result.destination.index, 0, reorderedItem);
          this.setState({
            OgFirstRow: stateClone,
          });
        } else if (result.source.droppableId === "compSecondRow") {
          //console.log("From Second Row");
          let secondClone = [...this.state.OgSecondRow];
          const [reorderedItem] = secondClone.splice(result.source.index, 1);
          stateClone.splice(result.destination.index, 0, reorderedItem)
          if (stateClone.length <= 4) {
            this.setState({
              OgFirstRow: stateClone,
              OgSecondRow: secondClone
            })
          } else {
            const [secondItem] = stateClone.splice(4, 1);
            secondClone.splice(0, 0, secondItem);
            this.setState({
              OgFirstRow: stateClone,
              OgSecondRow: secondClone
            });
          }
        } else if ((result.source.droppableId === "compOptions") || (result.source.droppableId === "navOptions")) {
          let optionsClone = [...this.state.navOptions];
          const [reorderedItem] = optionsClone.splice(result.source.index, 1);
          //console.log("ReOrdered Item Size:");
          //console.log(reorderedItem.size);
          if ((reorderedItem.size > 2)) {
            toast("Item is too large for this Area!")
            return;
          }
          if (stateClone.length >= 4) {
            toast("There is not enough space! \n Remove another card and Try Again")
            return;
          }
          stateClone.splice(result.destination.index, 0, reorderedItem)
          this.setState({
            OgFirstRow: stateClone,
            navOptions: optionsClone
          });
        }
      } else if (result.destination.droppableId === "compSecondRow") {
        //console.log("Adding to the Second Row");
        stateClone = [...this.state.OgSecondRow];
        if (result.source.droppableId === "compSecondRow") {
          const [reorderedItem] = stateClone.splice(result.source.index, 1);
          stateClone.splice(result.destination.index, 0, reorderedItem);
          this.setState({
            OgSecondRow: stateClone,
          })
        } else if (result.source.droppableId === "compFirstRow") {
          //console.log("From First Row");
          let secondClone = [...this.state.OgFirstRow];
          const [reorderedItem] = secondClone.splice(result.source.index, 1);
          stateClone.splice(result.destination.index, 0, reorderedItem)
          if (stateClone.length <= 4) {
            this.setState({
              OgFirstRow: secondClone,
              OgSecondRow: stateClone
            })
          } else {
            const [secondItem] = stateClone.splice(4, 1);
            secondClone.splice(0, 0, secondItem);
            this.setState({
              OgFirstRow: secondClone,
              OgSecondRow: stateClone
            })
          }
        } else if ((result.source.droppableId === "compOptions") || (result.source.droppableId === "navOptions")) {
          let optionsClone = [...this.state.navOptions];
          const [reorderedItem] = optionsClone.splice(result.source.index, 1);
          //console.log("ReOrdered Item Size:");
          //console.log(reorderedItem.size);
          // if ((reorderedItem.size > 3) || (stateClone.length >= 4)) {
          //   return;
          // }
          if ((reorderedItem.size > 2)) {
            toast("Item is too large for this Area!")
            return;
          }
          if (stateClone.length >= 4) {
            toast("There is not enough space! \n Remove another card and Try Again")
            return;
          }
          stateClone.splice(result.destination.index, 0, reorderedItem)
          this.setState({
            OgSecondRow: stateClone,
            navOptions: optionsClone
          });
        }
      } else {
        //console.log("ReOrdering Charts")
        stateClone = [...this.state.chartOgOrder];
        if ((result.source.droppableId === "compOptions") || (result.source.droppableId === "navOptions")) {
          let optionsClone = [...this.state.navOptions];
          const [reorderedItem] = optionsClone.splice(result.source.index, 1);
          //console.log("ReOrdered Item Size:");
          //console.log(reorderedItem.size);
          if ((reorderedItem.size !== 8)) {
            toast("Item is too small for this Area!")
            return;
          }
          if (stateClone.length >= 3) {
            toast("There is not enough space! \n Remove another chart and Try Again")
            return;
          }
          stateClone.splice(result.destination.index, 0, reorderedItem)
          this.setState({
            chartOgOrder: stateClone,
            navOptions: optionsClone
          });
        } else if ((result.source.droppableId === "compFirstRow") || (result.source.droppableId === "compSecondRow") ) {
          toast("Item is too small for this Area!")
          return;
        } else {
          const [reorderedItem] = stateClone.splice(result.source.index, 1);
          stateClone.splice(result.destination.index, 0, reorderedItem);
          //console.log(reorderedItem);
          //console.log(stateClone);
          this.setState({
            chartOgOrder: stateClone,
          });
        }
        
      }
    }

    closeCard = (index, listType) => {
      let stateClone;
      if (listType === 'Charts') {
        stateClone = [...this.state.chartOgOrder];
      } else if (listType === 'First_Row') {
        stateClone = [...this.state.OgFirstRow];
      } else if (listType === 'Second_Row') {
        stateClone = [...this.state.OgSecondRow];
      }
      let navClone = [...this.state.navOptions];
      const [reorderedItem] = stateClone.splice(index, 1);
      navClone.splice(navClone.length, 0, reorderedItem);
      
      if (listType === 'Charts') {
        this.setState({
          navOptions: navClone,
          chartOgOrder: stateClone
        });
      } else if (listType === 'First_Row') {
        this.setState({
          navOptions: navClone,
          OgFirstRow: stateClone
        });
      } else if (listType === 'Second_Row') {
        this.setState({
          navOptions: navClone,
          OgSecondRow: stateClone
        });
      }
      return;
    }  
  
    renderComps = (compObj, provided, index, listType) => {
      if (compObj.id.includes("mlc")) {
        //console.log("Returning MLC");
        return (
          <div className="col-span-8 text-right" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <button
              className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => this.closeCard(index, listType)}
            >
              Close
            </button>
            <div className="text-center">
              <compObj.comp height={400} width={1600} type={compObj.type} title={compObj.title} time={compObj.time} system={compObj.system} />
            </div>
            <hr className='mt-2'/>                   
          </div>
        )
      }
      return(
        <div className="col-span-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <compObj.comp timeFrame={compObj.time} location={compObj.location} />                            
        </div>
      )
    }
  
    render() {
      return (
        <DragDropContext onDragEnd={this.handleOnDragEnd}>
          {this.state.notLoggedIn && (
            //false
            <Navigate to="/" replace={true} />
          )}
          <div className="grid grid-cols-12">
            <Droppable droppableId="compOptions">
              {(provided, snapshot) => (
                <div className="lg:col-span-2 md:col-span-1" {...provided.droppableProps} ref={provided.innerRef}>
                  <Nav navOptions={this.state.navOptions} icon={"IP"} />
                  <div>
                    
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <div className="col-span-10">
              <Header compNum={3} state={this.state} system={'IP'} />
              <Droppable droppableId="compFirstRow" direction='horizontal'>
                {(provided, snapshot) => (
                  <div className="grid grid-cols-8 gap-1 border-dashed border-2 border-gray-150 mt-1 divide-x-2 divide-dashed divide-gray-150" {...provided.droppableProps} ref={provided.innerRef}>
                    {this.state.OgFirstRow.map( (component_info, index) => {
                      return (
                        <Draggable key={component_info.id} draggableId={component_info.id} index={index}>
                          {(provided, snapshot) => (
                            this.renderComps(component_info, provided, index, 'First_Row')
                          )}
                        </Draggable>
                      )
                    })}
                    {this.state.OgFirstRow.length ? <div className='lg:h-0'></div> : <div className='lg:h-80 pt-20 text-center text-7xl text-gray-200 italic'>Card Area</div> }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <Droppable droppableId="compSecondRow" direction='horizontal'>
                {(provided, snapshot) => (
                  <div className="grid grid-cols-8 gap-1 border-dashed border-2 border-gray-150 mt-1 divide-x-2 divide-dashed divide-gray-150" {...provided.droppableProps} ref={provided.innerRef}>
                    {this.state.OgSecondRow.map( (component_info, index) => {
                      return (
                        <Draggable key={component_info.id} draggableId={component_info.id} index={index}>
                          {(provided, snapshot) => (
                            this.renderComps(component_info, provided, index, 'Second_Row')
                          )}
                        </Draggable>
                      )
                    })}
                    {this.state.OgSecondRow.length ? <div className='lg:h-0'></div> : <div className='lg:h-80 pt-20 text-center text-7xl text-gray-200 italic'>Card Area</div>}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <Droppable droppableId="compCharts">
                {(provided, snapshot) => (
                  <div className="grid grid-cols-8 gap-1 border-dashed border-2 border-gray-150 mt-1 h-auto" {...provided.droppableProps} ref={provided.innerRef}>
                    {/* <hr className='mt-2'/> */}
                    {this.state.chartOgOrder.map( (component_info, index) => {
                      return (
                        <Draggable key={component_info.id} draggableId={component_info.id} index={index}>
                          {(provided, snapshot) => (
                            this.renderComps(component_info, provided, index, 'Charts')
                          )}
                        </Draggable>
                      )
                    })}
                    {this.state.chartOgOrder.length ? <div className='lg:h-0'></div> : <div className='lg:h-96 text-center text-7xl text-gray-200 italic'>Chart Area</div>}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
          
        </DragDropContext>
      )
    }
  }
  
  export default Iprint;