export const lineConverter = {
'932': 'Press',
'942':	'Press',
'4016':	'Press',
'4017':	'Press',
'4054':	'Press',
'4056':	'Press',
'4059':	'Press',
'4065':	'Press',
'4069':	'Press',
'4071':	'Press',
'4116':	'Press',
'4118':	'Press',
'4122':	'Press',
'4123':	'Press',
'4129':	'Press',
'4130':	'Press',
'4131':	'Press',
'4134':	'Press',
'4135':	'Press',
'4136':	'Press',
'4182':	'Press',
'4193':	'Press',
'4251':	'Press',
'4253':	'Press',
'4257':	'Press',
'4278':	'Press',
'4279':	'Press',
'4302':	'Press',
'4400':	'Press',
'4849':	'Press',
'4868':	'Press',
'4870':	'Press',
'5001':	'Press',
'5881':	'Press',
'5882':	'Press',
'5996':	'Press',
'5997':	'Press',
'20300': 'Mug',
'20301': 'Mug',
'20360': 'Mug',
'20361': 'Mug',
'20380': 'Mug',
'20400': 'Mug',
'20601': 'Mug',
'20601-1':'Mug',
'21002':'Mug',
'21002-1':'Mug',
'21012':'Mug',
'21013':'Mug',
'21108':'Mug',
'21110':'Mug',
'21110-1':'Mug',
'21111':'Mug',
'21111-1':'Mug',
'21112':'Mug',
'21112-1':'Mug',
'21113':'Mug',
'21113-1':'Mug',
'21114':'Mug',
'21114-1':'Mug',
'21115':'Mug',
'21115-1':'Mug',
'21116':'Mug',
'21116-1':'Mug',
'21117':'Mug',
'21117-1':'Mug',
'21118':'Mug',
'21118-1':'Mug',
'21119':'Mug',
'21119-1':'Mug',
'21120':'Mug',
'21120-1':'Mug',
'21121':'Mug',
'21121-1':'Mug',
'21122':'Mug',
'21122-1':'Mug',
'21123':'Mug',
'21123-1':'Mug',
'21124':'Mug',
'21124-1':'Mug',
'21125':'Mug',
'21125-1':'Mug',
'21126':'Mug',
'21126-1':'Mug',
'21127':'Mug',
'21127-1':'Mug',
'21128':'Mug',
'21128-1':'Mug',
'21129':'Mug',
'21129-1':'Mug',
'21130':'Mug',
'21130-1':'Mug',
'21131':'Mug',
'21131-1':'Mug',
'21132':'Mug',
'21132-1':'Mug',
'21133':'Mug',
'21133-1':'Mug',
'21134':'Mug',
'21134-1':'Mug',
'21135':'Mug',
'21135-1':'Mug',
'21136':'Mug',
'21136-1':'Mug',
'21137':'Mug',
'21137-1':'Mug',
'21138':'Mug',
'21138-1':'Mug',
'21139':'Mug',
'21139-1':'Mug',
'21140':'Mug',
'21140-1':'Mug',
'21141':'Mug',
'21141-1':'Mug',
'21142':'Mug',
'21142-1':'Mug',
'21143':'Mug',
'21143-1':'Mug',
'21146':'Mug',
'21146-1':'Mug',
'21150':'Mug',
'21150-1':'Mug',
'21151':'Mug',
'21151-1':'Mug',
'21156':'Mug',
'21156-1':'Mug',
'21166':'Mug',
'21168':'Mug',
'21197':'Mug',
'21201':'Mug',
'21211':'Mug',
'21216':'Mug',
'21268':'Mug',
'21269':'Mug',
'21270':'Mug',
'21271':'Mug',
'21480':'Mug',
'21480-1':'Mug',
'21482':'Mug',
'21483':'Mug',
'21483-1':'Mug',
'21484':'Mug',
'21486':'Mug',
'21504':'Mug',
'21511':'Mug',
'21511-1':'Mug',
'21512':'Mug',
'21512-1':'Mug',
'21513':'Mug',
'21513-1':'Mug',
'21514':'Mug',
'21514-1':'Mug',
'21515':'Mug',
'21515-1':'Mug',
'21516':'Mug',
'21516-1':'Mug',
'21517':'Mug',
'21517-1':'Mug',
'21521':'Mug',
'21521-1':'Mug',
'21522':'Mug',
'21522-1':'Mug',
'21523':'Mug',
'21523-1':'Mug',
'21524':'Mug',
'21524-1':'Mug',
'21526':'Mug',
'21526-1':'Mug',
'21527':'Mug',
'21527-1':'Mug',
'21531':'Mug',
'21550':'Mug',
'21560':'Mug',
'21561':'Mug',
'21562':'Mug',
'21563':'Mug',
'21564':'Mug',
'21565':'Mug',
'21566':'Mug',
'21567':'Mug',
'21568':'Mug',
'21569':'Mug',
'21570':'Mug',
'21610':'Mug',
'21612':'Mug',
'21618':'Mug',
'21620':'Mug',
'21622':'Mug',
'21623':'Mug',
'21625':'Mug',
'21626':'Mug',
'21627':'Mug',
'21628':'Mug',
'21634':'Mug',
'21642':'Mug',
'21645':'Mug',
'21648':'Mug',
'21651':'Mug',
'21652':'Mug',
'21659':'Mug',
'21660':'Mug',
'21661':'Mug',
'21665':'Mug',
'21701':'Mug',
'21716':'Mug',
'21750':'Mug',
'21763':'Mug',
'21765':'Mug',
'21801':'Mug',
'21811':'Mug',
'21812':'Mug',
'22010':'Mug',
'22210':'Mug',
'22216':'Mug',
'22217':'Mug',
'23300':'Mug',
'23301':'Mug',
'23302':'Mug',
'23303':'Mug',
'23304':'Mug',
'23502':'Mug',
'23515':'Mug',
'23516':'Mug',
'23517':'Mug',
'23518':'Mug',
'23523':'Mug',
'23600':'Mug',
'23601':'Mug',
'23617':'Mug',
'23618':'Mug',
'23621':'Mug',
'23622':'Mug',
'23660':'Mug',
'23663':'Mug',
'23664':'Mug',
'23665':'Mug',
'23666':'Mug',
'23751':'Mug',
'23753':'Mug',
'23754':'Mug',
'23755':'Mug',
'31006':'Mug',
'31013':'Mug',
'36001':'Press',
'36002':'Press',
'36003':'Press',
'36004':'Press',
'36006':'Press',
'40100':'Press',
'54404':'Press',
'54405':'Press',
'56601':'Press',
'56602':'Press',
'56607':'Press',
'56800':'Press',
'56801':'Press',
'58080':'Press',
'58081':'Press',
'59000':'Press',
'59001':'Press',
'59002':'Press',
'59004':'Press',
'59005':'Press',
'59006':'Press',
'59021':'Press',
'59022':'Press',
'59100':'Press',
'59101':'Press',
'71221':'Press',
'71222':'Press',
'71223':'Press',
'71231':'Press',
'71232':'Press',
'71233':'Press',
'71441':'Press',
'71442':'Press',
'71443':'Press',
'71445':'Press',
'71661':'Press',
'71662':'Press',
'71665':'Press',
'71681':'Press',
'71682':'Press',
'71685':'Press',
'72011':'Press',
'72012':'Press',
'72017':'Press',
'72021':'Press',
'72030':'Press',
'72031':'Press',
'72032':'Press',
'72039':'Press',
'72040':'Press',
'72041':'Press',
'72042':'Press',
'72043':'Press',
'72050':'Press',
'73104':'Press',
'73105':'Press',
'73106':'Press',
'73112':'Press',
'73113':'Press',
'73114':'Press',
'73119':'Press',
'73120':'Press',
'73121':'Press',
'73122':'Press',
'73126':'Press',
'73127':'Press',
'73128':'Press',
'73170':'Press',
'73171':'Press',
'73172':'Press',
'73173':'Press',
'73174':'Press',
'73176':'Press',
'73177':'Press',
'73178':'Press',
'73179':'Press',
'73187':'Press',
'73188':'Press',
'73189':'Press',
'73201':'Press',
'73202':'Press',
'73214':'Press',
'73215':'Press',
'73217':'Press',
'73218':'Press',
'73219':'Press',
'73220':'Press',
'73290':'Press',
'73291':'Press',
'73406':'Press',
'73407':'Press',
'73412':'Press',
'73413':'Press',
'73423':'Press',
'73424':'Press',
'73428':'Press',
'73429':'Press',
'73430':'Press',
'73431':'Press',
'73432':'Press',
'73433':'Press',
'73434':'Press',
'73435':'Press',
'73436':'Press',
'73437':'Press',
'73438':'Press',
'73439':'Press',
'73520':'Press',
'73526':'Press',
'73550':'Press',
'73556':'Press',
'73600':'Press',
'76000':'Press',
'76001':'Press',
'76002':'Press',
'76003':'Press',
'76005':'Press',
'80305':'Press',
'80306':'Press',
'80307':'Press',
'80500':'Press',
'80510':'Press',
'81002':'Press',
'81003':'Press',
'81005':'Press',
'81100':'Press',
'82001':'Press',
'82003':'Press',
'83001':'Press',
'84001':'Press',
'84002':'Press',
'84004':'Press',
'84005':'Press',
'84006':'Press',
'84007':'Press',
'84008':'Press',
'84010':'Press',
'84400':'Press',
'85000':'Press',
'85016':'Press',
'85022':'Press',
'85027':'Press',
'85047':'Press',
'85055':'Press',
'85056':'Press',
'85061':'Press',
'85070':'Press',
'85082':'Press',
'87004':'Press',
'87007':'Press',
'87008':'Press',
'87009':'Press',
'87015':'Press',
'87016':'Press',
'89007':	'Roller Press',
'89110':	'Roller Press',
'90001':'Press',
'91096':'Press',
'91097':'Press',
'91098':'Press',
'91103':'Press',
'91200':'Press',
'91201':'Press',
'91205':'Press',
'91241':'Press',
'91289':'Press',
'91299':'Press',
'91321':'Press',
'91322':'Press',
'91324':'Press',
'91600':'Press',
'91601':'Press',
'91602':'Press',
'91603':'Press',
'93105':'Press',
'123456':'Press',
'721769':'Press',
'726009':'Press',
'726019':'Press',
'726029':'Press',
'726039':'Press',
'726049':'Press',
'726059':'Press',
'726069':'Press',
'914494':'Press',
'1225':'Press',
'1225_2':'Press',
'21104AM':'Mug',
'21108-FB':'Mug',
'21230':'Mug',
'21502AM':'Press',
'2704B':'Press',
'2704N':'Press',
'2704R':'Press',
'2704RY':'Press',
'4400_12':'Press',
'4400_18':'Press',
'4767_L':'Press',
'4767_M':'Press',
'4767_S':'Press',
'4767_XL':'Press',
'4767_XXL':'Press',
'59000-1':'Press',
'59005-G':'Press',
'59006-1':'Press',
'61000':'Press',
'6507S':'Press',
'6630B':'Press',
'67500':'Press',
'73100-2':'Press',
'73179-1':'Press',
'76004':'Press',
'84400-2':'Press',
'850G':'Press',
'91099-T':'Press',
'91100-T':'Press',
'91101-T':'Press',
'91102-T':'Press',
'BARAPRON':'Press',
'CANV1114':'Press',
'CANV1620':'Press',
'CANV1824':'Press',
'CANV2436':	'Press',
'CANV810':'Press',
'CAP-White':'Press',
'CarmatCombo':'Press',
'CM20X30':'Press',
'CMB':'Press',
'CMF':'Press',
'ComboGC1':'Press',
'ComboGC10':'Press',
'ComboGC2':'Press',
'ComboGC3':'Press',
'ComboGC4':'Press',
'ComboGC5':'Press',
'ComboGC6':	'Press',
'ComboGC7':'Press',
'ComboGC8':	'Press',
'ComboGC9':	'Press',
'DM1824':'Press',
'DT001':'Press',
'FJX-TX115':'Press',
'FJX-XMAS025':'Press',
'FJX-XMAS026':'Press',
'FRAMH1':'Press',
'GC-CC1':'Press',
'GC-CC2':'Press',
'GC1':'Press',
'GC10':'Press',
'GC2':'Press',
'GC3':'Press',
'GC4':'Press',
'GC5':'Press',
'GC6':'Press',
'GC7':'Press',
'GC8':'Press',
'GC9':'Press',
'H-7001':	'Press',
'H1192':'Press',
'HUG2':'Press',
'HUG2-2':'Press',
'HUG6':'Press',
'INSERT11X17':'Press',
'INSERT8X11':'Press',
'12X12INSERT': 'Press',
'11X17INSERT': 'Press',
'ServingTray11x17': 'Press',
'TRAY12X12': 'Press',
'IYP-931':'Press',
'IYP-933':'Press',
'IYP-APA':'Press',
'IYP-PB':'Press',
'IYP-SAT':'Press',
'IYP-SC2533':'Press',
'LP025':'Press',
'M103PD-BLK':	'UV',
'M103PD-BLU':	'UV',
'M103PD-SIL':	'UV',
'M104PD-BLK':	'UV',
'MPB001':'Press',
'MR013':'Press',
'Ncube':'Press',
'P160_L':'Press',
'P160_M':'Press',
'P160_S':'Press',
'P160_XL':'Press',
'P160_XXL':'Press',
'PANDA':'Press',
'PCB':'Press',
'PCCanasta':'Press',
'PCFULL':'Press',
'PCJumbo':'Press',
'PCPinkPoker':'Press',
'PCPinochle':'Press',
'PCPoker':'Press',
'Pillow1818W':'Press',
'Pillow2222W':'Press',
'POS1013':'Press',
'POS1114':'Press',
'POS1620':'Press',
'POS1824':'Press',
'POS2030':'Press',
'POS2436':'Press',
'POSTER-L':	'Poster',
'POSTER-M':	'Poster',
'POSTER-S':	'Poster',
'PS1824A':'Press',
'PS2436A':'Press',
'PUZ110BOX':'Press',
'PUZ30BOX':'Press',
'RTIN':'Press',
'TBN':'Press',
'TBW':'Press',
'TOWEL11X18':'Press',
'TOWEL16X25':'Press',
'TOWEL22X38':'Press',
'TOWEL30x58':'Press',
'TRAY1120':'Press',
'TRAY11X17':'Press',
'TRAY8X11':'Press',
'TUMBLER':'Press',
'U1004':'Press',
'U1011':'Press',
'U1013':'Press',
'U1013_2sided':'Press',
'U5507':'Press',
'U5507_2sided':'Press',
'U5521':'Press',
'U5521_2sided':'Press',
'U5524':'Press',
'U5524_2sided':'Press',
'U5525':'Press',
'U5607':'Press',
'U5646':'Press',
'U5669':'Press',
'U5672':'Press',
'U5857':'Press',
'U5859':'Press',
'U5955':'Press',
'U5969':'Press',
'WFN_L':	'Outsourced',
'WFN_M':	'Outsourced',
'WFS_L':'Press',
'WFS_M':'Press',
'WINE2':'Press',
'WNN':	'Outsourced',
'WNN_PINK':	'Outsourced',
'WNN_RED':	'Outsourced',
'WP230CH':'Press',
'WP520CH':'Press',
'WPP_M':	'Outsourced',
'WPP_W':	'Outsourced',
'WRX_L':	'Outsourced',
'WRX_M':	'Outsourced',
'WSB_L':'Press',
'WSB_M':'Press',
'Yogamat1':'Press',
'Z91250':'Press',
'Z91251':'Press',
'Z91252':'Press',
'Z91253':'Press',
'Z91254':'Press',
'Z91255':'Press',
'Z91256':'Press',
'Z91257':'Press',
'Z91258':'Press',
'Z91259':'Press',
'Z91260':'Press',
'Z91261':'Press',
'Z91262':'Press',
'Z91263':'Press',
'Z91264':'Press',
'Z91265':'Press',
'Z91266':'Press',
'Z91267':'Press',
'Z91268':'Press',
'Z91269':'Press',
'Z91270':'Press',
'Z91271':'Press',
'Z91272':'Press',
'Z91273':'Press',
'Z91274':'Press',
'carmatcombo': 'Press',
}