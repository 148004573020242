import React from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as d3 from "d3";
import { BarChart } from "./helpers/SimpleBarChartHelper";
import * as orderStatsService from "../services/orderStats.service";
import moment from 'moment';

class SimpleBarChart extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      thisData: null
    }
  }
  componentDidMount() {
    orderStatsService.getSkuBars().then(response => {
      //console.log(response);
      let data = response.data;
      this.drawChart(data, this.props.width, this.props.height, this.props.type);
      this.setState({
        thisData: data,
      })
    }).catch(err => {
      console.log(err);
    })
    //const { data, width, height, type } = props;
    // console.log(this.props.data)
    // if (this.props.data.length > 0) {
    //   console.log("Do Something")
    //   this.drawChart(this.props.data, this.props.width, this.props.height, this.props.type);
    // } else {
    //   console.log("Do Nothing")
    // }
  }

//   componentDidUpdate(prevProps) {
//     this.drawChart(this.props.data, this.props.width, this.props.height, this.props.type);
//   }

  drawChart = (data, width, height, type) => {
    // Add logic to draw the chart here
    let chart;
    //console.log('Drawing Chart');
    // console.log(data);
    // console.log(width);
    // console.log(height);
    // console.log(type);
    chart = BarChart(data, {
      x: d => d.sku,
      y: d => d.frequency,
      xDomain: d3.groupSort(data, ([d]) => -d.frequency, d => d.sku), // sort by descending frequency
      //yFormat: "%",
      yLabel: `↑ ${type}`,
      width: width,
      height: height,
      color: "steelblue"
    })
    // chart = BarChart(data, {
    //   x: d => moment(d.date).toDate(),
    //   y: d => d[type],
    //   z: d => d.division,
    //   yLabel: `↑ ${type}`,
    //   width: width,
    //   height: height,
    //   color: "steelblue",
    //   //title: d.title,
    //   //voronoi: true // if true, show Voronoi overlay
    // })
    //console.log(chart)
    d3.select('#chart_' + this.props.type).exit().remove()
    d3.select('#chart_' + this.props.type).node().append(chart)
    //console.log(chart)
    return chart;
  }

  render() {
    return (
      <div className='mt-2'>      
        <h5 className='ml-4'>PVP Popular Items</h5>
        <div id={'chart_' + this.props.type} className="ml-4" />
      </div>
    )
  }
}
    
export default SimpleBarChart;