import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { lineConverter } from "./helpers/skuToLineOp"; 


export default function SkuModal(props) {
  const [showModal, setShowModal] = React.useState(false);

  const copyItem = (item) => {
    navigator.clipboard.writeText(item);
    toast(`Copied ${item} to Clipboard!`)
    return;
  }
  
  return (
    <>
      <button
        className="bg-gray-500 text-white active:bg-gray-600 uppercase text-xs px-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Skus
      </button>
      {showModal ? (
        <div onClick={() => setShowModal(false)}>
          <div
            className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-x-0 inset-y-0 z-50 max-h-screen w-full max-w-screen outline-none focus:outline-none"
          >
            <div onClick={e => e.stopPropagation()} className=" relative w-auto mx-auto max-w-3xl">
              {/*content*/}
              <div className="bg-gray-800 border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
                {/*header*/}
                <div className="flex bg-gray-800 items-start justify-between p-2 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-2xl text-white font-semibold">
                    SKU Totals
                  </h3>
                  <button
                    className="mb-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-white h-6 w-6 text-2xl block outline-none focus:outline-none">
                      x
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex overflow-x-auto overflow-y-auto shadow-md sm:rounded-lg">
                  
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400" >
                      <tr>
                        {props.cols.map((col, index) => {
                          return (
                            <th key={index} scope="col" className="px-4 py-2">
                              {col}
                            </th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {props.data.map((row, index) => 
                        ( 
                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                            <th key={index} className="px-6 py-4" onClick={() => copyItem(row[0])}>
                              {row[0]}
                            </th>
                            <th key={index} className="px-6 py-4" onClick={() => copyItem(row[1])}>
                              {row[1]}
                            </th>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      ) : null}
    </>
  );
}