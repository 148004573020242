import React from "react";
import * as orderStatsService from "../services/orderStats.service";
import choroplethChart from "./helpers/ChoroplethHelper";

class LocationsCard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      week: null,
      month: null,
      year: null,
      lastYear: null,
      season: null,
      currentUse: null,
      currentKeys: null,
    }
  }

  componentDidMount() {
    //console.log("TimeFrame");
    
    //let time = this.props.timeFrame;
    //let location = this.props.location;
    //console.log(this.props.timeFrame);
    //console.log(this.props.location);
    orderStatsService.getPopularLocation('week', this.props.location).then(response => {
      //console.log(response);
      //console.log(this.props.timeFrame);
      let timeObj;
      let weekObj = JSON.parse(response.week);
      let monthObj = JSON.parse(response.month);
      let yearObj = JSON.parse(response.year);
      let lastYearObj = JSON.parse(response.lastYear);
      let seasonObj = JSON.parse(response.season);
      let time = this.props.timeFrame;
      if (time === 'week') {
        timeObj = weekObj;
      } else if (time === 'month') {
        timeObj = monthObj;
      }  else if (time === 'year') {
        timeObj = yearObj;
      } else if (time === 'lastYear') {
        timeObj = lastYearObj;
      } else if (time === 'season') {
        timeObj = seasonObj;
      }
      this.setState({
        week: weekObj.quantity,
        month: monthObj.quantity,
        year: yearObj.quantity,
        lastYear: lastYearObj.quantity,
        season: seasonObj.quantity,
        currentUse: timeObj.quantity,
        currentKeys: Object.keys(timeObj.quantity)
      })

      //console.log(Object.keys(seasonObj));
    }).catch(err => {
      console.log(err);
    })
  }

  render() {
    return(
      <div class="shadow-lg text-white ml-0.5 mt-1 px-4 pb-9 py-6 w-full bg-slate-700 dark:bg-gray-800 relative">
        <p class="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          Popular Locations
        </p>
        <p class="flex text-sm items-end text-white dark:text-white font-semibold">
          Location : Quantity
        </p>
        <br />
        { this.state.currentUse ?
          this.state.currentKeys.map( (key, index) => {
            let currentValue = this.state.currentUse[key];
            return (
              <div key={index} class="flex pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
                <p>
                  {key}
                </p>
                <div class="flex items-end text-xs">
                  
                  <span class="flex items-center">
                  {currentValue}
                  </span>
                </div>
              </div>
            )
          }) : null}
      </div>
    )
  }
}

export default LocationsCard;