import * as d3 from "d3";
//import { legend } from "d3/color-legend"
import * as topojson from "topojson-client";
const states = require('./states.json');

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/choropleth

export function choroplethChart(data, max, min) {
  //console.log("Max");
  //console.log(max);
  //console.log("Min");
  //console.log(min)
  if ((min - 20) < 0) {
    min = 0;
  } else {
    min -= 20;
  }
  let color = d3.scaleQuantize([min, max], d3.schemeBlues[9])
  //console.log(color);
  let path = d3.geoPath()
  let format = d => `${d}`
  //console.log(states);
  //let us = JSON.parse(states);
  let us = states;

  data = Object.assign(new Map(data), {title: "Amount of Orders"});
  //console.log(data)

  const svg = d3.create("svg")
    .attr("viewBox", [0, 0, 975, 600]);
  
  svg.append("g")
    .attr("transform", "translate(610,20)")
    //.append(() => legend({color, title: data.title, width: 260}));
  
  svg.append("g")
    .selectAll("path")
    .data(topojson.feature(us, us.objects.states).features)
    .join("path")
      .attr("fill", d => {
        let value = data.get(d.properties.name);
        if (!value) {
          value = 0;
        }
        return color(value) 
      })
      .attr("d", path)
    .append("title")
      .text(d => `${d.properties.name}
  ${format( data.get(d.properties.name) ? data.get(d.properties.name) : 0 )}`);
  
  svg.append("path")
    .datum(topojson.mesh(us, us.objects.states, (a, b) => a !== b))
    .attr("fill", "none")
    .attr("stroke", "white")
    .attr("stroke-linejoin", "round")
    .attr("d", path);
  
  return svg.node();
}

export default choroplethChart;
