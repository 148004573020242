import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState, Component } from 'react';
import axios from 'axios';
import { createBrowserHistory } from "history";
import { BrowserRouter, Router, Route, Routes } from "react-router-dom";
import Layout from "./constants/Layout"
import Orders from './components/Orders';
import Login from './components/Login';
import Openprint from './components/Openprint';
import Subprint from './components/Subprint';
import Iprint from './components/Iprint';
import Graphics from './components/Graphics';
import KyOpenprint from './components/KentuckyOp';
import { ToastContainer } from 'react-toastify';


const history = createBrowserHistory();

function App() {
  const [getMessage, setGetMessage] = useState({})

  // useEffect(()=>{
  //   axios.get('http://localhost:5000/flask/test').then(response => {
  //     console.log("SUCCESS", response)
  //     setGetMessage(response)
  //   }).catch(error => {
  //     console.log(error)
  //   })

  // }, [])


  return (
    <React.Fragment>
      <BrowserRouter location={history.location} navigator={history}>
        <Routes>
        
          {/* <Route path="/auth" component={NoLayoutRouter} /> */}
          <Route path="/" element={ <Login /> } />
        
          <Route path="/printverse" element={ <Orders /> } />

          <Route path="/openprint" element={ <Openprint /> } />

          <Route path="/subprint" element={ <Subprint /> } />

          <Route path="/iprint" element={ <Iprint /> } />

          <Route path="/graphics" element={ <Graphics /> } />

          <Route path="/kentuckyop" element={ <KyOpenprint /> } />
      
        </Routes>
        <ToastContainer pauseOnFocusLoss={false} />
      </BrowserRouter>
    </React.Fragment>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Testing React + Flask
    //     </p>
    //     <div>{getMessage.status === 200 ? 
    //       <h3>{getMessage.data.message}</h3>
    //       :
    //       <h3>LOADING</h3>}
    //     </div>
    //   </header>
    // </div>
  );
}

export default App;
