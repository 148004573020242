import React, { useState } from 'react';
import { Navigate } from "react-router-dom";
import Nav from './Nav';
import Header from './Header';
import OrdersDue from './ky/KyOpOrdersDue';
import ItemsDue from './ky/KyOpItemsDue';
import LateOrders from './ky/KyOpLateOrders';
import OpOrdersShipped from './ky/KyOpOrdersShipped';
import OpItemsShipped from './ky/KyOpItemsShipped';
import DailyReport from './DailyReport';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Cookies from 'js-cookie';
import moment from 'moment';

class KyOpenprint extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        comps: {
          "OpLateOrders": [LateOrders, null],
          "OpOrdersDue": [OrdersDue, null],
          "OpItemsDue": [ItemsDue, null],
          // "OpOrderCard": [OpQueueCard, null],
          // "OpOrderItemsCard": [OpItemsQueueCard, null],
          "OpOrdersShipped": [OpOrdersShipped, null],
          "OpItemsShipped": [OpItemsShipped, null],
          // "OpOrdersBySkuCard": [OpSkuCard, null],
          // "OpInventory": [OpInventory, null],
          // "OpSubmitDefects": [SubmitDefects, null],
          // "OpOrdersCanceled": [OpCancelCard, null],
          // "OpShippingErrors": [OpShipErrors, null],
          // "OpOrderCycle": [OpOrderCycleCard, null],
          // "OpSkuStats": [OpSkuStats, null],
          // "OpMixedOrderCycle": [OpMixedOrderCycleCard, null],
          // "OpSkuShipped": [SkuShipped, null],
          // "OpRevenue": [OpRevenue, null],
          // "LineChart_Orders_Day": [MultiLineChart, "Orders"],
          // "LineChart_Orders_Month": [MultiLineChart, "Orders"],
          // "LineChart_Items_Day": [MultiLineChart, "Items"],
          // "LineChart_Items_Month": [MultiLineChart, "Items"],
          // "Order Map: Season": [LocationStateChart, "Order Map: Season"],
          // "Order Map: This Year": [LocationStateChart, `Order Map: ${moment().year()}`],
          // "Order Map: Last Year": [LocationStateChart, `Order Map: ${moment().year() - 1}`],
          // "Splom": [Splom, null],
          "DailyReport": [DailyReport, null],
          // "OpSkuPieChart": [SkuPieChart, "Items Sold by Sku"],
          // "OpCompanyPieChart": [CompanyPieChart, "Items Sold by Sku"],
          // "Orders FM/KY": [OpFMvKY, null]
        },
        navOptions: [
          ////{id: "orders", name: "OpOrderCard", comp: OpQueueCard, size: 2},

          // {id: "submitDefectsOp", name: "OpSubmitDefects", comp: SubmitDefects, size: 2},

          ////{id: "skuShippedOp", name: "OpSkuShipped", comp: SkuShipped, system:"OP", size: 2},

          // {id: "skuStatsOp", name: "OpSkuStats", comp: OpSkuStats, size: 2},

          ////{id: "ordersCanceled", name: "OpOrdersCanceled", comp: OpCancelCard, size: 2},
          ////{id: "shippingErrors", name: "OpShippingErrors", comp: OpShipErrors, size: 2},
          ////{id: "orderCycleOp", name: "OpOrderCycle", comp: OpOrderCycleCard, size: 2},
          ////{id: "orderMixedCycleOp", name: "OpMixedOrderCycle", comp: OpMixedOrderCycleCard, size: 2},
          ////{id: "FMvKYOp", name: "Orders FM/KY", comp: OpFMvKY, size: 2},
          
          // {id: "revenueOp", name: "OpRevenue", comp: OpRevenue, type: "items", context: "created", title: "Items", time: "month", system: "OP", size: 2}, 
          // {id: "mlc items shipped month OP", name: "LineChart_Shipped_Month", comp: MultiLineChart, type: "items", context: "shipping", title: "Items Shipped", time: "month", system: "OP", size: 8},
          // {id: "mlc items shipped day OP", name: "LineChart_Shipped_Day", comp: MultiLineChart, type: "items", context: "shipping", title: "Items Shipped", time: "day", system: "OP", size: 8},
          
          ////{id: "mlc items month OP", name: "LineChart_Items_Month", comp: MultiLineChart, type: "items", context: "created", title: "Items", time: "month", system: "OP", size: 8},
          ////{id: "mlc items day OP", name: "LineChart_Items_Day", comp: MultiLineChart, type: "items", context: "created", title: "Items", time: "day", system: "OP", size: 8},
          ////{id: "op season state location map", name: "Order Map: Season", comp: LocationStateChart, type: "Map", title: "Order Map: Season", time:"season", location:"state", system:"OP", size: 8 },
          ////{id: "op lastYear state location map", name: "Order Map: Last Year", comp: LocationStateChart, type: "Map", title: `Order Map: ${moment().year() - 1}`, time:"lastYear", location:"state", system:"OP", size: 8 },
          
          // {id: "splom", name: "Splom", comp: Splom, type: "Matrix", title: "Scatter Plot Matrix", size: 8},
          
          {id: "opDailyReport", name: "DailyReport", comp: DailyReport, system: "KY", size: 2},
          ////{id: "op sku pie chart", name: "OpSkuPieChart", comp: SkuPieChart, type: "items", context: "shipped", title: "Items Sold by Sku", time: "all", system: "OP", size: 8},
          ////{id: "op company pie chart", name: "OpCompanyPieChart", comp: CompanyPieChart, type: "items", context: "shipped", title: "Items Sold by Sku", time: "all", system: "OP", size: 8},
        ],
        chartOgOrder: [
          //{id: "mlc orders month OP", name: "LineChart_Orders_Month", comp: MultiLineChart, type: "orders",  context: "created", title: "Orders", time: "month", system: "OP", size: 8},
          //{id: "mlc orders day OP", name: "LineChart_Orders_Day", comp: MultiLineChart, type: "orders",  context: "created", title: "Orders", time: "day", system: "OP", size: 8},
          //{id: "op year state location map", name: "Order Map: This Year", comp: LocationStateChart, type: "Map", title: `Order Map: ${moment().year()}`, time:"year", location:"state", system:"OP", size: 8 },
        ],
        OgFirstRow: [
          {id: "ordersDue", name: "OpOrdersDue", comp: OrdersDue, size: 2},
          {id: "itemsDue", name: "OpItemsDue", comp: ItemsDue, size: 2},
          {id: "lateOrders", name: "OpLateOrders", comp: LateOrders, size: 2},
          
          //{id: "orderItems", name: "OpOrderItemsCard", comp: OpItemsQueueCard, size: 2},
        ],
        OgSecondRow: [
          {id: "ordersShipped", name: "OpOrdersShipped", comp: OpOrdersShipped, size: 2},
          {id: "itemsShipped", name: "OpItemsShipped", comp: OpItemsShipped, size: 2},
          //{id: "ordersSku", name: "OpOrdersBySkuCard", comp: OpSkuCard, size: 2},
          //{id: "inventory", name: "OpInventory", comp: OpInventory, size: 2},
          
        ]
      }
    }
  
    componentDidMount() {
      let currEmail = localStorage.getItem('UserEmail');
      let cookie = Cookies.get('token');
      if (!currEmail || !cookie) {
        this.setState({
          notLoggedIn: true,
        })
      }
      let currSettings = Cookies.get('cardSettingsKYOP');
      let localCurrSettings = localStorage.getItem('cardSettingsKYOP');
      if (currSettings || localCurrSettings) {
        let settings;
        if (currSettings) {
          settings = currSettings;
        } else {
          settings = localCurrSettings;
        }
        settings = JSON.parse(settings);
        let keys = Object.keys(settings)
        for (let i=0; i < keys.length; i++) {
          let currKey = keys[i];
          if ((currKey === 'OgFirstRow') || (currKey === 'OgSecondRow') || (currKey === 'chartOgOrder') || (currKey === 'navOptions')) {
            let currArr = settings[currKey];
            for (let j=0; j < currArr.length; j++) {
              let currObj = currArr[j];
              currObj['comp'] = this.state.comps[currObj['name']][0];
              currObj['title'] = this.state.comps[currObj['name']][1];
              currArr[j] = currObj;
            }
            settings[currKey] = currArr
          }
          
        } 
        this.setState({
          navOptions: settings.navOptions,
          OgFirstRow: settings.OgFirstRow,
          OgSecondRow: settings.OgSecondRow,
          chartOgOrder: settings.chartOgOrder,
        });
      }
      
    }
    handleOnDragEnd = (result) => {
      let stateClone;
      if ((result.destination.droppableId === "compOptions") || (result.destination.droppableId === "navOptions")) {
        console.log("Removing Card From Grid")
        if (result.source.droppableId === "compFirstRow") {
          stateClone = [...this.state.OgFirstRow];
        } else if (result.source.droppableId === "compSecondRow") {
          stateClone = [...this.state.OgSecondRow];
        } else if (result.source.droppableId === "compCharts") {
          stateClone = [...this.state.chartOgOrder];
        }
        if (!stateClone) {
          if ((result.source.droppableId === "compOptions") || (result.source.droppableId === "navOptions")) {
            stateClone = [...this.state.navOptions];
            const [reorderedItem] = stateClone.splice(result.source.index, 1);
            stateClone.splice(result.destination.index, 0, reorderedItem);
            this.setState({
              navOptions: stateClone,
            });
          }
          return;
        }
        const [reorderedItem] = stateClone.splice(result.source.index, 1);
        let optionsClone = [...this.state.navOptions];
        optionsClone.splice(optionsClone.length, 0, reorderedItem);
        if (result.source.droppableId === "compFirstRow") {
          this.setState({
            OgFirstRow: stateClone,
          });
        } else if (result.source.droppableId === "compSecondRow") {
          this.setState({
            OgSecondRow: stateClone,
          });
        } else if (result.source.droppableId === "compCharts") {
          this.setState({
            chartOgOrder: stateClone,
          });
        }
        this.setState({
          navOptions: optionsClone
        });
      } else if (result.destination.droppableId === "compFirstRow") {
        stateClone = [...this.state.OgFirstRow];
        if (result.source.droppableId === "compFirstRow") {
          const [reorderedItem] = stateClone.splice(result.source.index, 1);
          stateClone.splice(result.destination.index, 0, reorderedItem);
          this.setState({
            OgFirstRow: stateClone,
          });
        } else if (result.source.droppableId === "compSecondRow") {
          let secondClone = [...this.state.OgSecondRow];
          const [reorderedItem] = secondClone.splice(result.source.index, 1);
          stateClone.splice(result.destination.index, 0, reorderedItem)
          if (stateClone.length <= 4) {
            this.setState({
              OgFirstRow: stateClone,
              OgSecondRow: secondClone
            })
          } else {
            const [secondItem] = stateClone.splice(4, 1);
            secondClone.splice(0, 0, secondItem);
            this.setState({
              OgFirstRow: stateClone,
              OgSecondRow: secondClone
            });
          }
        } else if ((result.source.droppableId === "compOptions") || (result.source.droppableId === "navOptions")) {
          let optionsClone = [...this.state.navOptions];
          const [reorderedItem] = optionsClone.splice(result.source.index, 1);
          if ((reorderedItem.size > 2)) {
            toast("Item is too large for this Area!")
            return;
          }
          if (stateClone.length >= 4) {
            toast("There is not enough space! \n Remove another card and Try Again")
            return;
          }
          stateClone.splice(result.destination.index, 0, reorderedItem)
          this.setState({
            OgFirstRow: stateClone,
            navOptions: optionsClone
          });
        }
      } else if (result.destination.droppableId === "compSecondRow") {
        stateClone = [...this.state.OgSecondRow];
        if (result.source.droppableId === "compSecondRow") {
          const [reorderedItem] = stateClone.splice(result.source.index, 1);
          stateClone.splice(result.destination.index, 0, reorderedItem);
          this.setState({
            OgSecondRow: stateClone,
          })
        } else if (result.source.droppableId === "compFirstRow") {
          let secondClone = [...this.state.OgFirstRow];
          const [reorderedItem] = secondClone.splice(result.source.index, 1);
          stateClone.splice(result.destination.index, 0, reorderedItem)
          if (stateClone.length <= 4) {
            this.setState({
              OgFirstRow: secondClone,
              OgSecondRow: stateClone
            })
          } else {
            const [secondItem] = stateClone.splice(4, 1);
            secondClone.splice(0, 0, secondItem);
            this.setState({
              OgFirstRow: secondClone,
              OgSecondRow: stateClone
            })
          }
        } else if ((result.source.droppableId === "compOptions") || (result.source.droppableId === "navOptions")) {
          let optionsClone = [...this.state.navOptions];
          const [reorderedItem] = optionsClone.splice(result.source.index, 1);
          // if ((reorderedItem.size > 3) || (stateClone.length >= 4)) {
          //   return;
          // }
          if ((reorderedItem.size > 2)) {
            toast("Item is too large for this Area!")
            return;
          }
          if (stateClone.length >= 4) {
            toast("There is not enough space! \n Remove another card and Try Again")
            return;
          }
          stateClone.splice(result.destination.index, 0, reorderedItem)
          this.setState({
            OgSecondRow: stateClone,
            navOptions: optionsClone
          });
        }
      } else {
        stateClone = [...this.state.chartOgOrder];
        if ((result.source.droppableId === "compOptions") || (result.source.droppableId === "navOptions")) {
          let optionsClone = [...this.state.navOptions];
          const [reorderedItem] = optionsClone.splice(result.source.index, 1);
          if ((reorderedItem.size !== 8)) {
            toast("Item is too small for this Area!")
            return;
          }
          if (stateClone.length >= 4) {
            toast("There is not enough space! \n Remove another chart and Try Again")
            return;
          }
          stateClone.splice(result.destination.index, 0, reorderedItem)
          this.setState({
            chartOgOrder: stateClone,
            navOptions: optionsClone
          });
        } else if ((result.source.droppableId === "compFirstRow") || (result.source.droppableId === "compSecondRow") ) {
          toast("Item is too small for this Area!")
          return;
        } else {
          const [reorderedItem] = stateClone.splice(result.source.index, 1);
          stateClone.splice(result.destination.index, 0, reorderedItem);
          this.setState({
            chartOgOrder: stateClone,
          });
        }
        
      }
    }
  
    renderComps = (compObj, provided, index, listType) => {
      if (compObj.id.includes("mlc")) {
        return (
          <div className="col-span-8 text-right" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <button
              className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => this.closeCard(index, listType)}
            >
              Close
            </button>
            <div className="text-center">
              <compObj.comp height={400} width={1600} type={compObj.type} title={compObj.title} time={compObj.time} system={compObj.system} context={compObj.context} />
            </div>
            <hr className='mt-2'/>                   
          </div>
        )
      } else if (compObj.id.includes("location")) {
        if (compObj.id.includes("map")) {
          return (
            <div className="col-span-8 text-right" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <button
                className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-0 ease-linear transition-all duration-150"
                type="button"
                onClick={() => this.closeCard(index, listType)}
              >
                Close
              </button>
              <div className="col-span-8 text-center" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <compObj.comp height={400} width={1600} type={compObj.type} time={compObj.time} location={compObj.location} title={compObj.title} system={compObj.system} />                            
              </div>
              <hr className='mt-2'/>
            </div>
          )
        }
      } else if (compObj.id.includes("pie")) {
        return (
          <div className="col-span-8 text-right" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <button
              className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => this.closeCard(index, listType)}
            >
              Close
            </button>
            <div className="text-center">
              <compObj.comp height={500} width={800} type={compObj.type} title={compObj.title} time={compObj.time} system={compObj.system} context={compObj.context} />
            </div>
            <hr className='mt-2'/>                   
          </div>
        )
      } 
      return(
        <div className="col-span-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <compObj.comp timeFrame={compObj.time} location={compObj.location} system={compObj.system} />                            
        </div>
      )
    }
  
    closeCard = (index, listType) => {
      let stateClone;
      if (listType === 'Charts') {
        stateClone = [...this.state.chartOgOrder];
      } else if (listType === 'First_Row') {
        stateClone = [...this.state.OgFirstRow];
      } else if (listType === 'Second_Row') {
        stateClone = [...this.state.OgSecondRow];
      }
      let navClone = [...this.state.navOptions];
      const [reorderedItem] = stateClone.splice(index, 1);
      navClone.splice(navClone.length, 0, reorderedItem);
      
      if (listType === 'Charts') {
        this.setState({
          navOptions: navClone,
          chartOgOrder: stateClone
        });
      } else if (listType === 'First_Row') {
        this.setState({
          navOptions: navClone,
          OgFirstRow: stateClone
        });
      } else if (listType === 'Second_Row') {
        this.setState({
          navOptions: navClone,
          OgSecondRow: stateClone
        });
      }
      return;
    }
  
    saveSettings = () => {
      return;
    }
  
    render() {
      return (
        <DragDropContext onDragEnd={this.handleOnDragEnd}>
          {this.state.notLoggedIn && (
            //false
            <Navigate to="/" replace={true} />
          )}
          <div className="grid grid-cols-12">
            <Droppable droppableId="compOptions">
              {(provided, snapshot) => (
                <div className="lg:col-span-2 md:col-span-1" {...provided.droppableProps} ref={provided.innerRef}>
                  <Nav navOptions={this.state.navOptions} icon={"OP"} />
                  <div>
                    
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <div className="col-span-10">
              <Header compNum={5} state={this.state} system={'KYOP'} />
              <Droppable droppableId="compFirstRow" direction='horizontal'>
                {(provided, snapshot) => (
                  <div className="grid grid-cols-8 gap-1 border-dashed border-2 border-gray-150 mt-1 divide-x-2 divide-dashed divide-gray-150" {...provided.droppableProps} ref={provided.innerRef}>
                    {this.state.OgFirstRow.map( (component_info, index) => {
                      return (
                        <Draggable key={component_info.id} draggableId={component_info.id} index={index}>
                          {(provided, snapshot) => (
                            this.renderComps(component_info, provided, index, 'First_Row')
                          )}
                        </Draggable>
                      )
                    })}
                    {this.state.OgFirstRow.length ? <div className='lg:h-0'></div> : <div className='lg:h-80 pt-20 text-center text-7xl text-gray-200 italic'>Card Area</div> }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <Droppable droppableId="compSecondRow" direction='horizontal'>
                {(provided, snapshot) => (
                  <div className="grid grid-cols-8 gap-1 border-dashed border-2 border-gray-150 mt-1 divide-x-2 divide-dashed divide-gray-150" {...provided.droppableProps} ref={provided.innerRef}>
                    {this.state.OgSecondRow.map( (component_info, index) => {
                      return (
                        <Draggable key={component_info.id} draggableId={component_info.id} index={index}>
                          {(provided, snapshot) => (
                            this.renderComps(component_info, provided, index, 'Second_Row')
                          )}
                        </Draggable>
                      )
                    })}
                    {this.state.OgSecondRow.length ? <div className='lg:h-0'></div> : <div className='lg:h-80 pt-20 text-center text-7xl text-gray-200 italic'>Card Area</div>}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <Droppable droppableId="compCharts">
                {(provided, snapshot) => (
                  <div className="grid grid-cols-8 gap-1 border-dashed border-2 border-gray-150 mt-1 h-auto" {...provided.droppableProps} ref={provided.innerRef}>
                    {/* <hr className='mt-2'/> */}
                    {this.state.chartOgOrder.map( (component_info, index) => {
                      return (
                        <Draggable key={component_info.id} draggableId={component_info.id} index={index}>
                          {(provided, snapshot) => (
                            this.renderComps(component_info, provided, index, 'Charts')
                          )}
                        </Draggable>
                      )
                    })}
                    {this.state.chartOgOrder.length ? <div className='lg:h-0'></div> : <div className='lg:h-96 text-center text-7xl text-gray-200 italic'>Chart Area</div>}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
          
        </DragDropContext>
      )
    }
  }
  
  export default KyOpenprint;