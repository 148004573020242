import axios from "axios";
import * as env from "../config/env";
const baseUrl = `${env.url}/stats/op`;
const OP_URL = `${env.OpBarcodeUrl}`;
const OP_USER_TOKEN = `${env.OP_USER_TOKEN}`;

const headers = {
  "User-Token": env.userToken,
};

const opHeaders = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'OP-USER-TOKEN': OP_USER_TOKEN
}

export function getOrders() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/orders`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getOrdersDueToday() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/dueToday?day=${"today"}&facility=${"ky"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getOrdersDueTmrw() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/dueToday?day=${"tmrw"}&facility=${"ky"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}
  
export function getItemsDueToday() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/itemsDue?day=${"today"}&facility=${"ky"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}
  
export function getItemsDueTmrw() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/itemsDue?day=${"tmrw"}&facility=${"ky"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getLateOrders() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/lateOrders?facility=${"ky"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}
  
export function getLateItems() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/lateItems?facility=${"ky"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getOrdersShipped() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/ordersShipped?facility=${"ky"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getItemsShipped() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/itemsShipped?facility=${"ky"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getDailyReport() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/dailyReport?facility=${"ky"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

const responseSuccessHandler = response => {
  return response.data;
};
    
const responseErrorHandler = error => {
  console.log(error);
  return Promise.reject(error);
};