import React from "react";
import * as opOrderStatsService from "../services/openprintStats.service";
import { IoIosRefresh } from "react-icons/io";

class OpSkuStats extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        
      }
    }
  
    componentDidMount() {
      opOrderStatsService.getSkuStats().then(response => {
        console.log(response);
        // let myTodayOrders = response.numShipped.today;
        // let myWeekOrders = response.numShipped.week;
        // let myMonthOrders = response.numShipped.month;
        // let myYearOrders = response.numShipped.year;
        // let myLastYearOrders = response.numShipped.lastYear;
        
        // this.setState({ 
        //   today: myTodayOrders,
        //   week: myWeekOrders,
        //   month: myMonthOrders,
        //   year: myYearOrders,
        //   lastYear: myLastYearOrders,
        // });
      }).catch(err => {
        console.log(err);
      })
    }

    refresh = () => {
      this.componentDidMount();
      return;
    }
  
    render() {
      return (      
        <div class="shadow-lg ml-0.5 mt-1 px-4 py-8 w-full bg-gray-800 relative grow">
          <span class="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
            Sku Stats
          </span>
          <button onClick={this.refresh} className="float-right text-md text-white">
            <IoIosRefresh className="hover:text-green-700" />
          </button>
          <div class="flex items-end space-x-2 my-6">
            <p class="text-5xl text-white dark:text-white font-bold">
              {this.state.today}
            </p>
          </div>
          <div class="text-white">
            <div class="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
              <span>
                This Week
              </span>
              <div class="flex items-end text-xs">
                {this.state.week}
              </div>
            </div>
            <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
              <span>
                This Month
              </span>
              <div class="flex items-end text-xs">
                {this.state.month}
              </div>
            </div>
            <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
              <span>
                This Year
              </span>
              <div class="flex items-end text-xs">
                {this.state.year}
              </div>
            </div>
            <div class="flex items-center text-sm space-x-12 md:space-x-24 justify-between">
              <span>
                Last Year
              </span>
              <div class="flex items-end text-xs">
                {this.state.lastYear}
              </div>
            </div>
          </div>
        </div>
    )
  }
}
  
export default OpSkuStats;