import React from "react";
import Modal from "../Modal";
import * as spOrderStatsService from "../../services/subprintStats.service";
import { IoIosRefresh } from "react-icons/io";

class SpQueueCard extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        allQueue: null,
        totalInQueue: null,
        notComposited: null,
        notDownloaded: null,
        notPrinted: null,
        ready: null,
        entireObj: {},
        needsCompositeObj: {},
        needsDownloadObj: {},
        downloadedObj: {},
        printedObj: {}
      }
    }
  
    componentDidMount() {
      spOrderStatsService.getOrders().then(response => {
        //console.log("Queue Card");
        //console.log(response);
        let queueNums = response.queueNums;
        let totalQueue = queueNums.numTotalQueue;
        let numNotComposited = queueNums.numNotComposited;
        let numNotDownloaded = queueNums.numNotDownloaded;
        let numNotPrinted = queueNums.numDownloaded;
        let numReady = queueNums.readyQueue

        let queues = response.queues;
        let entireQueue = JSON.parse(queues.Queue);
        let needsComposite = JSON.parse(queues.toComposite)
        let needsDownload = JSON.parse(queues.toDownload)
        let downloaded = JSON.parse(queues.downloaded)
        let printed = JSON.parse(queues.printed)
        //console.log("Entire Queue");
        //console.log(entireQueue);
        this.setState({ 
          allQueue: queueNums,
          totalInQueue: totalQueue,
          notComposited: numNotComposited,
          notDownloaded: numNotDownloaded,
          notPrinted: numNotPrinted,
          ready: numReady,
          entireObj: entireQueue,
          needsCompositeObj: needsComposite,
          needsDownloadObj: needsDownload,
          downloadedObj: downloaded,
          printedObj: printed
        });
      }).catch(err => {
        console.log(err);
      })
    }

    refresh = () => {
      //console.log("REFRESH");
      this.componentDidMount();
      return;
    }
  
    render() {
      return (      
        <div class="shadow-lg ml-0.5 mt-1 px-4 py-6 w-full bg-slate-700 dark:bg-gray-800 relative">
          <span class="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
            Orders Ready <Modal data={this.state.printedObj} numCols={2} />
          </span>
          <button onClick={this.refresh} className="float-right text-md text-white">
            <IoIosRefresh className="hover:text-green-700" />
          </button>
          <div class="flex items-end space-x-2 my-6">
            <p class="text-5xl text-white dark:text-white font-bold">
              {this.state.ready}
            </p>
          </div>
          <div class="text-white">
            <div class="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
              <span>
                Total Orders <Modal data={this.state.entireObj} numCols={2} />
              </span>
              <div class="flex items-end text-xs">
                {this.state.totalInQueue}
              </div>
            </div>
            <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
              <span>
                Newly Created <Modal data={this.state.needsCompositeObj} numCols={2} />
              </span>
              <div class="flex items-end text-xs">
                {this.state.notComposited}
              </div>
            </div>
            <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
              <span>
                Composite Queue <Modal data={this.state.needsDownloadObj} numCols={2} />
              </span>
              <div class="flex items-end text-xs">
                {this.state.notDownloaded}
              </div>
            </div>
            <div class="flex items-center text-sm space-x-12 md:space-x-24 justify-between">
              <span>
                Not Printed <Modal data={this.state.downloadedObj} numCols={2} />
              </span>
            <div class="flex items-end text-xs">
              {this.state.notPrinted}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
  
export default SpQueueCard;