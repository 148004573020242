import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "./Modal";
import * as opOrderStatsService from "../services/openprintStats.service";
import { IoIosRefresh } from "react-icons/io";

class OpShipErrors extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      numErrors: null,
      errorObj: {}
    }
  }
  
  componentDidMount() {
    opOrderStatsService.getShipErrors().then(response => {
      //console.log(response);
      let numReady = response.errorNum;
      let errors = JSON.parse(response.errors);
      this.setState({ 
        numErrors: numReady,
        errorObj: errors
      });
    }).catch(err => {
      console.log(err);
    })
  }
  
  refresh = () => {
    //console.log("REFRESH");
    this.componentDidMount();
    return;
  }
  
  render() {
    return (      
      <div className="shadow-lg ml-0.5 mt-1 px-4 py-6 w-full bg-gray-800 relative grow">
        <span className="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          Shipping Errors <Modal data={this.state.errorObj} numCols={2} shippingErrors={true} />
        </span>
        <button onClick={this.refresh} className="float-right text-md text-white">
          <IoIosRefresh className="hover:text-green-700" />
        </button>
        <div className="flex items-end space-x-2 my-6 mb-40">
          <p className="text-5xl text-white dark:text-white font-bold">
            {this.state.numErrors}
          </p>
        </div>
        {/* <hr className="mb-5" /> */}
        {/* <span className="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          Orders Due Next Business Day <Modal data={this.state.tmrwObj} numCols={4} fullArt={true} itemIds={this.state.itemMap} />
        </span>
        <div className="flex items-end space-x-2 my-6">
          <p className="text-5xl text-white dark:text-white font-bold">
            {this.state.readyTmrw}
          </p>
        </div> */}
      </div>
    )
  }
}
  
export default OpShipErrors;
