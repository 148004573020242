import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "./Modal";
import * as opOrderStatsService from "../services/openprintStats.service";
import * as ipOrderStatsService from "../services/iprintStats.service";
import * as spOrderStatsService from "../services/subprintStats.service";
import { IoIosRefresh } from "react-icons/io";

class SkuShipped extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      sku: '',
      time: '',
      reason: '',
      shippedQuantity: '',
      spin: false
      //timeFrames: ['Today', 'Week', 'Month', 'Year', 'LastYear']
    }

    this.handleChangeSku = this.handleChangeSku.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
    this.handleChangeReason = this.handleChangeReason.bind(this);
    this.submitCount = this.submitCount.bind(this);
  }
  
  submitCount = (event) => {
    event.preventDefault();
    
    
    let data = {
      sku: this.state.sku,
      time: this.state.time,
    }
    
    this.setState({
      shippedQuantity: '',
      spin: true
    });

    let service;
    if (this.props.system === 'OP') {
      service = opOrderStatsService;
    } else if (this.props.system === 'SP') {
      service = spOrderStatsService;
    } else if (this.props.system === 'IP') {
      service = ipOrderStatsService;
    }

    service.getSkuShipped(data).then(response => {
      this.setState({
        shippedQuantity: response.skuNum,
        spin: false
      });
    //   toast(`Submitted ${this.state.quantity} Defects For Sku: ${this.state.sku} - Reason: ${this.state.reason}`);
    }).catch(err => {
     console.log(err);
    })

    return;
  }

  handleChangeSku = (event) => {
    this.setState({sku: event.target.value});
  }

  handleChangeTime = (event) => {
    this.setState({time: event.target.value});
  }

  handleChangeReason = (event) => {
    this.setState({reason: event.target.value});
  }

  render() {
    return (
      <div className="shadow-lg ml-0.5 mt-1 px-1 py-2 mt-8 w-full bg-slate-700 dark:bg-gray-800 relative flex place-items-center">
        <form className="space-y-3.5" onSubmit={this.submitCount}>
          <h5 className="text-md w-max text-white dark:text-white font-semibold border-b border-gray-200">Items Shipped By Sku</h5>
          <div>
            <label htmlFor="sku" className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300">SKU</label>
            <input type="text" name="sku" id="sku" value={this.state.sku} onChange={this.handleChangeSku} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="21108" required />
          </div>
          <div>
            <label htmlFor="time" className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300">Timeframe</label>
            <select name="time" id="time" value={this.state.time} onChange={this.handleChangeTime} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required > 
              <option value="today">Today</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
              <option value="lastYear">Last Year</option>
            </select>
          </div>
          {/* <div>
            <label htmlFor="reason" className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300">Reason</label>
            <input type="text" name="reason" id="reason" value={this.state.reason} onChange={this.handleChangeReason} placeholder="Broken" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
          </div> */}
          <button type="submit" className="w-24 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-center">Submit</button>
        </form>
        <div className="w-max text-center text-white p-2 mt-1 ml-3 dark:text-white font-semibold border border-gray-300 flex flex-wrap flex-row place-items-center">
          <p className="text-xl w-max text-white font-semibold flex place-content-center pr-1">Items Shipped: </p>
          <br />
          
          <p className="text-2xl flex place-content-center "> {(this.state.shippedQuantity || this.state.shippedQuantity == 0) ? `${this.state.sku} --> ${this.state.shippedQuantity}` : `Sku --> Quantity`}</p> {/* <Modal data={this.state.printedObj} numCols={2} /> */}
          {this.state.spin ? 
            <svg role="status" className="inline w-8 h-8 mr-2 text-gray-200 float-center animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg> : null }
        </div>
          {/* <div class="flex items-end space-x-2 my-6">
            <p class="text-5xl text-white dark:text-white font-bold">
              {this.state.shippedQuantity}
            </p>
          </div> */}
      </div>
    )
  }
}

export default SkuShipped;