import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "../Modal";
import * as ipOrderStatsService from "../../services/iprintStats.service";
import { IoIosRefresh } from "react-icons/io";

class IpLateOrders extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      ready: null,
      lateObj: {},
      readyItems: null,
      lateItemsObj: {}
    }
  }

  componentDidMount() {
    ipOrderStatsService.getLateOrders().then(response => {
      let numReady = response.dueNum
      let due = JSON.parse(response.due)
      
      this.setState({ 
        ready: numReady,
        lateObj: due
      });

    }).catch(err => {
      console.log(err);
    })

    ipOrderStatsService.getLateItems().then(response => {
      let numReady = response.dueNum
      let due = JSON.parse(response.due)
        
      this.setState({ 
        readyItems: numReady,
        lateItemsObj: due
      });
  
    }).catch(err => {
      console.log(err);
    })
  }

  refresh = () => {
    //console.log("REFRESH");
    this.componentDidMount();
    return;
  }

  render() {
    return (      
      <div className="shadow-lg ml-0.5 mt-1 px-4 py-6 w-full bg-slate-700 dark:bg-gray-800 relative">
        <span className="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          Late Orders <Modal data={this.state.lateObj} numCols={4} />
        </span>
        <button onClick={this.refresh} className="float-right text-md text-white">
          <IoIosRefresh className="hover:text-green-700" />
        </button>
        <div className="flex items-end space-x-2 my-6">
          <p className="text-5xl text-white dark:text-white font-bold">
            {this.state.ready}
          </p>
        </div>
        <hr className="mb-5" />
        <span className="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          Late Items <Modal data={this.state.lateItemsObj} numCols={4} />
        </span>
        <div className="flex items-end space-x-2 my-6">
          <p className="text-5xl text-white dark:text-white font-bold">
            {this.state.readyItems}
          </p>
        </div>
      </div>
    )
  }
}

export default IpLateOrders;