import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import Modal from "./Modal";
import * as opOrderStatsService from "../services/openprintStats.service";
import { IoIosRefresh } from "react-icons/io";

class SubmitDefects extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      sku: '',
      quantity: '',
      reason: ''
    }

    this.handleChangeSku = this.handleChangeSku.bind(this);
    this.handleChangeQuantity = this.handleChangeQuantity.bind(this);
    this.handleChangeReason = this.handleChangeReason.bind(this);
    this.submitCount = this.submitCount.bind(this);
  }
  
  submitCount = (event) => {
    event.preventDefault();
    console.log("Submitting Defects");
    console.log(this.state.sku);
    console.log(this.state.quantity);
    console.log(this.state.reason);
    
    let data = {
      sku: this.state.sku,
      quantity: this.state.quantity,
      reason: this.state.reason,
      system: this.props.system
    }

    // opOrderStatsService.submitDefects(data).then(response => {
    //   console.log(response);
    //   this.setState({
    //     quantity: '',
    //     sku: '',
    //     reason: ''
    //   });
    //   toast(`Submitted ${this.state.quantity} Defects For Sku: ${this.state.sku} - Reason: ${this.state.reason}`);
    // }).catch(err => {
    //  console.log(err);
    // })

    return;
  }

  handleChangeSku = (event) => {
    this.setState({sku: event.target.value});
  }

  handleChangeQuantity = (event) => {
    this.setState({quantity: event.target.value});
  }

  handleChangeReason = (event) => {
    this.setState({reason: event.target.value});
  }

  render() {
    return (
      <div className="shadow-lg ml-0.5 mt-1 px-2 py-1.5 w-full bg-slate-700 dark:bg-gray-800 relative">
        <form className="space-y-3.5" onSubmit={this.submitCount}>
          <h5 className="text-md w-max text-white dark:text-white font-semibold border-b border-gray-200">Submit Defects</h5>
          <div>
            <label htmlFor="sku" className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300">SKU</label>
            <input type="text" name="sku" id="sku" value={this.state.sku} onChange={this.handleChangeSku} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="21108" required />
          </div>
          <div>
            <label htmlFor="quantity" className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300">Quantity</label>
            <input type="text" name="quantity" id="quantity" value={this.state.quantity} onChange={this.handleChangeQuantity} placeholder="99999" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
          </div>
          <div>
            <label htmlFor="reason" className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300">Reason</label>
            <input type="text" name="reason" id="reason" value={this.state.reason} onChange={this.handleChangeReason} placeholder="Broken" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
          </div>
          <button type="submit" className="w-24 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-center">Submit</button>
        </form>
      </div>
    )
  }
}

export default SubmitDefects;