import React, { useState } from 'react';
import { Navigate } from "react-router-dom";
import Nav from './Nav';
import Header from './Header';
import OrderTextCard from './OrderTextCard';
import RevenueTextCard from './RevenueTextCard';
import SKUInfoCard from './SKUInfoCard';
import MultiLineChart from './MultiLineChart';
import RefundInfoCard from './RefundInfoCard';
import RefundAmountCard from './RefundAmountCard';
import IntlShippingCard from './IntlShippingCard';
import IntlShippingYearCard from './IntlShippingYearCard';
import IntlShippingMonthCard from './IntlShippingMonthCard';
import SimpleBarChart from './SimpleBarChart';
import OrderCycleCard from './OrderCycleCard';
import LocationsCard from './LocationsCard';
import LocationStateChart from './LocationStateChart';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import moment from 'moment';
import Cookies from 'js-cookie'
import * as orderStatsService from "../services/orderStats.service";
import { chordTranspose } from 'd3';
import { toast } from 'react-toastify';

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notLoggedIn: null,
      comps: {
        "OrderTextCard": [OrderTextCard, null],
        "RevenueTextCard": [RevenueTextCard, null],
        "RefundInfoCard": [RefundInfoCard, null],
        "RefundAmountCard": [RefundAmountCard, null],
        "SKUInfoCard": [SKUInfoCard, null],
        "IntlShippingCard": [IntlShippingCard, null],
        "IntlShippingYearCard": [IntlShippingYearCard, null],
        "IntlShippingMonthCard": [IntlShippingMonthCard, null],
        "OrderCycleCard": [OrderCycleCard, null],
        "LineChart_Orders_Month": [MultiLineChart, "Orders"],
        "LineChart_Revenue_Month": [MultiLineChart, "Revenue"],
        "Sku_Quantity_BarChart": [SimpleBarChart, null],
        "Order Map: Season": [LocationStateChart, "Order Map: Season"],
        "Order Map: This Year": [LocationStateChart, `Order Map: ${moment().year()}`],
        "LineChart_Orders_Day": [MultiLineChart, "Orders"],
        "LineChart_Revenue_Day": [MultiLineChart, "Revenue"],
        "Order Map: Last Year": [LocationStateChart, `Order Map: ${moment().year() - 1}`]
      },
      chartOgOrder: [
        {id: "mlc orders day", name: "LineChart_Orders_Day", comp: MultiLineChart, type: "orders", title: "Orders", time: "day", system: "PV", size: 8},
        {id: "mlc revenue day", name: "LineChart_Revenue_Day", comp: MultiLineChart, type: "revenue", title: "Revenue", time: "day", system: "PV", size: 8},
        {id: "year state location map", name: "Order Map: This Year", comp: LocationStateChart, type: "Map", title: `Order Map: ${moment().year()}`, time:"year", location:"state", system:"PV", size: 8 },
      ],
      OgFirstRow: [
        {id: "orders", name: "OrderTextCard", comp: OrderTextCard, size: 2}, 
        {id: "revenue", name: "RevenueTextCard", comp: RevenueTextCard, size: 2}, 
        {id: "refund_info", name: "RefundInfoCard", comp: RefundInfoCard, size: 2}, 
        {id: "refund_amount", name: "RefundAmountCard", comp: RefundAmountCard, size: 2},
      ],
      OgSecondRow: [
        {id: "sku_info", name: "SKUInfoCard", comp: SKUInfoCard, size: 2}, 
        {id: "intl", name: "IntlShippingCard", comp: IntlShippingCard, size: 2}, 
        {id: "intl_year", name: "IntlShippingYearCard", comp: IntlShippingYearCard, size: 2}, 
        {id: "intl_month", name: "IntlShippingMonthCard", comp: IntlShippingMonthCard, size: 2},
      ],
      navOptions: [
        {id: "order cycle", name: "OrderCycleCard", comp: OrderCycleCard, size: 2},
        //{id: "location city card", name: "Locations Card: City", comp: LocationsCard, time:'year', location: 'city', size: 2},
        //{id: "location state card", name: "Locations Card: State", comp: LocationsCard, time:'year', location: 'state', size: 2},
        {id: "mlc orders month", name: "LineChart_Orders_Month", comp: MultiLineChart, type: "orders", title: "Orders", time: "month", system: "PV", size: 8},
        {id: "mlc revenue month", name: "LineChart_Revenue_Month", comp: MultiLineChart, type: "revenue", title: "Revenue", time: "month", system: "PV", size: 8},
        {id: "barChart sku quantity", name: "Sku_Quantity_BarChart", comp: SimpleBarChart, type: "Quantity", size: 8},
        {id: "season state location map", name: "Order Map: Season", comp: LocationStateChart, type: "Map", title: "Order Map: Season", time:"season", location:"state", system:"PV", size: 8 },
        {id: "lastYear state location map", name: "Order Map: Last Year", comp: LocationStateChart, type: "Map", title: `Order Map: ${moment().year() - 1}`, time:"lastYear", location:"state", system:"PV", size: 8 }
      ],
      rowLength: 4,
      // OgOrder: [
      //   OgFirstRow,
      //   OgSecondRow,
      //   chartOgOrder,
      // ],
    }
  };

  handleOnDragEnd = (result) => {
    //console.log(result);
    let stateClone;
    if ((result.destination.droppableId === "compOptions") || (result.destination.droppableId === "navOptions")) {
      console.log("Removing Card From Grid")
      if (result.source.droppableId === "compFirstRow") {
        stateClone = [...this.state.OgFirstRow];
      } else if (result.source.droppableId === "compSecondRow") {
        stateClone = [...this.state.OgSecondRow];
      } else if (result.source.droppableId === "compCharts") {
        stateClone = [...this.state.chartOgOrder];
      }
      if (!stateClone) {
        //console.log('Nothing')
        if ((result.source.droppableId === "compOptions") || (result.source.droppableId === "navOptions")) {
          stateClone = [...this.state.navOptions];
          const [reorderedItem] = stateClone.splice(result.source.index, 1);
          stateClone.splice(result.destination.index, 0, reorderedItem);
          this.setState({
            navOptions: stateClone,
          });
        }
        return;
      }
      const [reorderedItem] = stateClone.splice(result.source.index, 1);
      let optionsClone = [...this.state.navOptions];
      optionsClone.splice(optionsClone.length, 0, reorderedItem);
      //console.log(reorderedItem);
      //console.log(stateClone);
      if (result.source.droppableId === "compFirstRow") {
        this.setState({
          OgFirstRow: stateClone,
        });
      } else if (result.source.droppableId === "compSecondRow") {
        this.setState({
          OgSecondRow: stateClone,
        });
      } else if (result.source.droppableId === "compCharts") {
        this.setState({
          chartOgOrder: stateClone,
        });
      }
      this.setState({
        navOptions: optionsClone
      });
    } else if (result.destination.droppableId === "compFirstRow") {
      //console.log("Adding to the First Row");
      stateClone = [...this.state.OgFirstRow];
      if (result.source.droppableId === "compFirstRow") {
        //console.log("Same Row Base Case");
        const [reorderedItem] = stateClone.splice(result.source.index, 1);
        stateClone.splice(result.destination.index, 0, reorderedItem);
        this.setState({
          OgFirstRow: stateClone,
        });
      } else if (result.source.droppableId === "compSecondRow") {
        //console.log("From Second Row");
        let secondClone = [...this.state.OgSecondRow];
        const [reorderedItem] = secondClone.splice(result.source.index, 1);
        stateClone.splice(result.destination.index, 0, reorderedItem)
        if (stateClone.length <= this.state.rowLength) {
          this.setState({
            OgFirstRow: stateClone,
            OgSecondRow: secondClone
          })
        } else {
          const [secondItem] = stateClone.splice(this.state.rowLength, 1);
          secondClone.splice(0, 0, secondItem);
          this.setState({
            OgFirstRow: stateClone,
            OgSecondRow: secondClone
          });
        }
      } else if ((result.source.droppableId === "compOptions") || (result.source.droppableId === "navOptions")) {
        let optionsClone = [...this.state.navOptions];
        const [reorderedItem] = optionsClone.splice(result.source.index, 1);
        //console.log("ReOrdered Item Size:");
        //console.log(reorderedItem.size);
        if ((reorderedItem.size > 2)) {
          toast("Item is too large for this Area!")
          return;
        }
        if (stateClone.length >= this.state.rowLength) {
          toast("There is not enough space! \n Remove another card and Try Again")
          return;
        }
        stateClone.splice(result.destination.index, 0, reorderedItem)
        this.setState({
          OgFirstRow: stateClone,
          navOptions: optionsClone
        });
      }
    } else if (result.destination.droppableId === "compSecondRow") {
      //console.log("Adding to the Second Row");
      stateClone = [...this.state.OgSecondRow];
      if (result.source.droppableId === "compSecondRow") {
        const [reorderedItem] = stateClone.splice(result.source.index, 1);
        stateClone.splice(result.destination.index, 0, reorderedItem);
        this.setState({
          OgSecondRow: stateClone,
        })
      } else if (result.source.droppableId === "compFirstRow") {
        //console.log("From First Row");
        let secondClone = [...this.state.OgFirstRow];
        const [reorderedItem] = secondClone.splice(result.source.index, 1);
        stateClone.splice(result.destination.index, 0, reorderedItem)
        if (stateClone.length <= this.state.rowLength) {
          this.setState({
            OgFirstRow: secondClone,
            OgSecondRow: stateClone
          })
        } else {
          const [secondItem] = stateClone.splice(this.state.rowLength, 1);
          secondClone.splice(0, 0, secondItem);
          this.setState({
            OgFirstRow: secondClone,
            OgSecondRow: stateClone
          })
        }
      } else if ((result.source.droppableId === "compOptions") || (result.source.droppableId === "navOptions")) {
        let optionsClone = [...this.state.navOptions];
        const [reorderedItem] = optionsClone.splice(result.source.index, 1);
        //console.log("ReOrdered Item Size:");
        //console.log(reorderedItem.size);
        // if ((reorderedItem.size > 3) || (stateClone.length >= 4)) {
        //   return;
        // }
        if ((reorderedItem.size > 2)) {
          toast("Item is too large for this Area!")
          return;
        }
        if (stateClone.length >= this.state.rowLength) {
          toast("There is not enough space! \n Remove another card and Try Again")
          return;
        }
        stateClone.splice(result.destination.index, 0, reorderedItem)
        this.setState({
          OgSecondRow: stateClone,
          navOptions: optionsClone
        });
      }
    } else {
      //console.log("ReOrdering Charts")
      stateClone = [...this.state.chartOgOrder];
      if ((result.source.droppableId === "compOptions") || (result.source.droppableId === "navOptions")) {
        let optionsClone = [...this.state.navOptions];
        const [reorderedItem] = optionsClone.splice(result.source.index, 1);
        //console.log("ReOrdered Item Size:");
        //console.log(reorderedItem.size);
        if ((reorderedItem.size !== 8)) {
          toast("Item is too small for this Area!")
          return;
        }
        if (stateClone.length >= 3) {
          toast("There is not enough space! \n Remove another chart and Try Again")
          return;
        }
        stateClone.splice(result.destination.index, 0, reorderedItem)
        this.setState({
          chartOgOrder: stateClone,
          navOptions: optionsClone
        });
      } else if ((result.source.droppableId === "compFirstRow") || (result.source.droppableId === "compSecondRow") ) {
        toast("Item is too small for this Area!")
        return;
      } else {
        const [reorderedItem] = stateClone.splice(result.source.index, 1);
        stateClone.splice(result.destination.index, 0, reorderedItem);
        //console.log(reorderedItem);
        //console.log(stateClone);
        this.setState({
          chartOgOrder: stateClone,
        });
      }
      
    }
    // this.setState({
    //   OgOrder: stateClone,
    // })
  }

  closeCard = (index, listType) => {
    let stateClone;
    if (listType === 'Charts') {
      stateClone = [...this.state.chartOgOrder];
    } else if (listType === 'First_Row') {
      stateClone = [...this.state.OgFirstRow];
    } else if (listType === 'Second_Row') {
      stateClone = [...this.state.OgSecondRow];
    }
    let navClone = [...this.state.navOptions];
    const [reorderedItem] = stateClone.splice(index, 1);
    navClone.splice(navClone.length, 0, reorderedItem);
    
    if (listType === 'Charts') {
      this.setState({
        navOptions: navClone,
        chartOgOrder: stateClone
      });
    } else if (listType === 'First_Row') {
      this.setState({
        navOptions: navClone,
        OgFirstRow: stateClone
      });
    } else if (listType === 'Second_Row') {
      this.setState({
        navOptions: navClone,
        OgSecondRow: stateClone
      });
    }
    return;
  }

  renderComps = (compObj, provided, index, listType) => {
    //console.log(compObj.id);
    if (compObj.id.includes("mlc")) {
      //console.log("Returning MLC");
      return (
        <div className="col-span-8 text-right" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <button
            className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => this.closeCard(index, listType)}
          >
            Close
          </button>
          <div className="text-center">
            <compObj.comp height={400} width={1600} type={compObj.type} title={compObj.title} time={compObj.time} system={compObj.system} />
          </div>
          <hr className='mt-2'/>                   
        </div>
      )
    } else if (compObj.id.includes("barChart")) {
      //console.log("Returning BarChart");
      return (
        <div className="col-span-8 text-right" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <button
            className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-0 ease-linear transition-all duration-150"
            type="button"
            onClick={() => this.closeCard(index, listType)}
          >
            Close
          </button>
          <div className="col-span-8 text-center" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <compObj.comp height={400} width={1600} type={compObj.type} />                            
          </div>
          <hr className='mt-2'/>
        </div>
      )
    } else if (compObj.id.includes("location")) {
      if (compObj.id.includes("map")) {
        return (
          <div className="col-span-8 text-right" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <button
              className="text-blue-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-0 ease-linear transition-all duration-150"
              type="button"
              onClick={() => this.closeCard(index, listType)}
            >
              Close
            </button>
            <div className="col-span-8 text-center" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <compObj.comp height={400} width={1600} type={compObj.type} time={compObj.time} location={compObj.location} title={compObj.title} system={compObj.system} />                            
            </div>
            <hr className='mt-2'/>
          </div>
        )
      } else {
        //console.log("LOCATIONS CARD");
        return(
          <div className="col-span-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <compObj.comp timeFrame={compObj.time} location={compObj.location} />                            
          </div>
        )
      }
    }
    //console.log("Returning Normal");
    return (
      <div className="lg:col-span-2 md:col-span-3 sm:col-span-3" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <compObj.comp  />                            
      </div>
    )
  }
  resizeLarge = () => {
    let currentHideNav = (window.innerWidth >= 1495);
    if (currentHideNav !== this.state.hideNav) {
      this.setState({rowLength: 4});
    }
  }
  //const [comps, updateComps] = useState([]);
  resizeSmall = () => {
    let currentHideNav = (window.innerWidth < 1495);
    if (currentHideNav !== this.state.hideNav) {
        let stateClone;
        let navClone;
        if (this.state.OgFirstRow.length > 3) {
          stateClone = [...this.state.OgFirstRow];
          navClone = [...this.state.navOptions];
          const [reorderedItem] = stateClone.splice(3, 1);
          navClone.splice(navClone.length, 0, reorderedItem);
          this.setState({
            OgFirstRow: stateClone,
            navOptions: navClone
          });
        }
        if (this.state.OgSecondRow.length > 3) {
          stateClone = [...this.state.OgSecondRow];
          navClone = [...this.state.navOptions];
          const [reorderedItem] = stateClone.splice(3, 1);
          navClone.splice(navClone.length, 0, reorderedItem);
          this.setState({
            OgSecondRow: stateClone,
            navOptions: navClone
          });
        }
        this.setState({rowLength: 3});
    }
  }
  componentDidMount() {
    let currEmail = localStorage.getItem('UserEmail');
    let cookie = Cookies.get('token');
    let role = localStorage.getItem('Role');
    if (!currEmail || !cookie) {
      this.setState({
        notLoggedIn: true,
      })
    } else {
      this.setState({
        role: role
      })
    }
    //window.addEventListener("resize", this.resizeSmall.bind(this));
    //window.addEventListener("resize", this.resizeLarge.bind(this));
    //let currSettings;
    let currSettings = Cookies.get('cardSettingsPV');
    if (currSettings) {
      currSettings = JSON.parse(currSettings);
      let keys = Object.keys(currSettings)
      for (let i=0; i < keys.length; i++) {
        let currKey = keys[i];
        if ((currKey === 'OgFirstRow') || (currKey === 'OgSecondRow') || (currKey === 'chartOgOrder') || (currKey === 'navOptions')) {
          let currArr = currSettings[currKey];
          for (let j=0; j < currArr.length; j++) {
            let currObj = currArr[j];
            currObj['comp'] = this.state.comps[currObj['name']][0];
            currObj['title'] = this.state.comps[currObj['name']][1];
            currArr[j] = currObj;
          }
          currSettings[currKey] = currArr
        }
        
      }
      this.setState({
        navOptions: currSettings.navOptions,
        OgFirstRow: currSettings.OgFirstRow,
        OgSecondRow: currSettings.OgSecondRow,
        chartOgOrder: currSettings.chartOgOrder,
      });
    }
  }
  // function handleOnDragEnd(result) {
  //   console.log(result);
  // }

  // <div className="col-span-2" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
  //   <component_info.comp  />                            
  // </div>

  render() {
    return(
      <DragDropContext onDragEnd={this.handleOnDragEnd}>
        {this.state.notLoggedIn && (
          //false
          <Navigate to="/" replace={true} />
        )}
        <div className="grid grid-cols-12">
          <Droppable droppableId="compOptions">
            
            {(provided, snapshot) => (
              <div className="lg:col-span-2 md:col-span-2 sm:col-span-1" {...provided.droppableProps} ref={provided.innerRef}>
                <Nav navOptions={this.state.navOptions} icon={"PV"} />
                
                {provided.placeholder}
              </div>
            )}
            
            
          </Droppable>
          <div className="col-span-10">
            <Header compNum={0} state={this.state} system={'PV'} />
            <Droppable droppableId="compFirstRow" direction='horizontal'>
              {(provided, snapshot) => (
                <div className="grid lg:grid-cols-8 md:grid-cols-9 sm:grid-cols-9 gap-1 border-dashed border-2 border-gray-150 mt-1 divide-x-2 divide-dashed divide-gray-150" {...provided.droppableProps} ref={provided.innerRef}>
                  {this.state.OgFirstRow.map( (component_info, index) => {
                    return (
                      <Draggable key={component_info.id} draggableId={component_info.id} index={index}>
                        {(provided, snapshot) => (
                          this.renderComps(component_info, provided, index, 'First_Row')
                        )}
                      </Draggable>
                    )
                  })}
                  {this.state.OgFirstRow.length ? <div className='lg:h-0'></div> : <div className='lg:h-80 pt-20 text-center text-7xl text-gray-200 italic'>Card Area</div> }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Droppable droppableId="compSecondRow" direction='horizontal'>
              {(provided, snapshot) => (
                <div className="grid lg:grid-cols-8 md:grid-cols-9 sm:grid-cols-9 gap-1 border-dashed border-2 border-gray-150 mt-1 divide-x-2 divide-dashed divide-gray-150" {...provided.droppableProps} ref={provided.innerRef}>
                  {this.state.OgSecondRow.map( (component_info, index) => {
                    return (
                      <Draggable key={component_info.id} draggableId={component_info.id} index={index}>
                        {(provided, snapshot) => (
                          this.renderComps(component_info, provided, index, 'Second_Row')
                        )}
                      </Draggable>
                    )
                  })}
                  {this.state.OgSecondRow.length ? <div className='lg:h-0'></div> : <div className='lg:h-80 pt-20 text-center text-7xl text-gray-200 italic'>Card Area</div>}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            
            <Droppable droppableId="compCharts">
              {(provided, snapshot) => (
                <div className="grid grid-cols-8 gap-1 border-dashed border-2 border-gray-150 mt-1 h-auto" {...provided.droppableProps} ref={provided.innerRef}>
                  {/* <hr className='mt-2'/> */}
                  {this.state.chartOgOrder.map( (component_info, index) => {
                    return (
                      <Draggable key={component_info.id} draggableId={component_info.id} index={index}>
                        {(provided, snapshot) => (
                          this.renderComps(component_info, provided, index, 'Charts')
                        )}
                      </Draggable>
                    )
                  })}
                  {this.state.chartOgOrder.length ? <div className='lg:h-0'></div> : <div className='lg:h-96 text-center text-7xl text-gray-200 italic'>Chart Area</div>}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
        
      </DragDropContext>
    )
  }
}

export default Orders;
