import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "../Modal";
import * as ipOrderStatsService from "../../services/iprintStats.service";
import { IoIosRefresh } from "react-icons/io";

class IpInventory extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      ready: null,
      inventoryObj: {},
      mug_1108: null,
      puzzle_91097: null,
      puzzle_91098: null,
      cmf: null,
      mpb: null,
      cuttingBoard: null,
      puzzle_91096: null
    }
  }

  componentDidMount() {
    ipOrderStatsService.getInventory().then(response => {
      let inventory = JSON.parse(response.inventory)
      this.setState({ 
        inventoryObj: inventory,
        mug_1108: inventory.data[0][3],
        puzzle_91096: inventory.data[12][3],
        puzzle_91097: inventory.data[13][3],
        puzzle_91098: inventory.data[14][3],
        cmf: inventory.data[20][3],
        mpb: inventory.data[24][3],
        cuttingBoard: inventory.data[11][3],
        
        
      });
    }).catch(err => {
      console.log(err);
    })
  }

  refresh = () => {
    this.componentDidMount();
    return;
  }

  render() {
    return (      
      <div className="shadow-lg ml-0.5 mt-1 px-4 py-6 w-full bg-gray-800 relative grow">
        <span className="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          Inventory <Modal data={this.state.inventoryObj} numCols={5} />
        </span>
        <button onClick={this.refresh} className="float-right text-md text-white">
          <IoIosRefresh className="hover:text-green-700" />
        </button>
        <div class="text-white">
          <div class="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <span>
              21108
            </span>
            <div class="flex items-end text-xs">
              {this.state.mug_1108}
            </div>
          </div>
          <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <span>
              91096
            </span>
            <div class="flex items-end text-xs">
              {this.state.puzzle_91096}
            </div>
          </div>
          <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <span>
              91097
            </span>
            <div class="flex items-end text-xs">
              {this.state.puzzle_91097}
            </div>
          </div>
          <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <span>
              91098
            </span>
            <div class="flex items-end text-xs">
              {this.state.puzzle_91098}
            </div>
          </div>
          <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <span>
              CMF
            </span>
            <div class="flex items-end text-xs">
              {this.state.cmf}
            </div>
          </div>
          <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <span>
              MPB001
            </span>
            <div class="flex items-end text-xs">
              {this.state.mpb}
            </div>
          </div>
          <div class="flex items-center text-sm space-x-12 md:space-x-24 justify-between">
            <span>
              76001
            </span>
            <div class="flex items-end text-xs">
              {this.state.cuttingBoard}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default IpInventory;