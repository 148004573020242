import React from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as orderStatsService from "../services/orderStats.service";


class SKUInfoCard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      today: null,
      week: null,
      month: null,
      year: null,
      all: null,
    }
  }

  componentDidMount() {
    orderStatsService.getPopSkus().then(response => {
      //console.log(response);
      let todayPop = response.today;
      let weekPop = response.week;
      let monthPop = response.month;
      let yearPop = response.year;
      let allPop = response.all;

      this.setState({
        today: todayPop,
        week: weekPop,
        month: monthPop,
        year: yearPop,
        all: allPop
      })
    }).catch(err => {
      console.log(err);
    })
  }

  render() {
    return (      
      <div class="shadow-lg ml-0.5 mt-1 px-4 pb-9 py-6 w-full bg-slate-700 dark:bg-gray-800 relative">
        <p class="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          Popular Items
        </p>
        <p class="flex text-sm items-end text-white dark:text-white font-semibold">
          Sku : Quantity
        </p>
        {/* <div class="flex items-end space-x-2 my-6">
          <p class="text-5xl text-white dark:text-white font-bold">
            {this.props.today}
          </p>
          <span class="text-green-500 text-xl font-bold flex items-center">
            <svg width="20" fill="currentColor" height="20" class="h-3" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
              <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
              </path>
            </svg>
            22%
          </span>
        </div> */}
        <br />
        <div class="text-white">
          <div class="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <p>
              Today  
            </p>
            <div class="flex items-end text-xs">
              {this.state.today ? `${this.state.today[0] ? this.state.today[0] : 'N/A : '} :` : 'N/A : '}
              <span class="flex items-center">
                {/* <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                  </path>
                </svg> */}
                {this.state.today ? this.state.today[1] : ' N/A'}
              </span>
            </div>
          </div>
          <div class="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <p>
              This Week  
            </p>
            <div class="flex items-end text-xs">
              {this.state.week ? `${this.state.week[0] ? this.state.week[0] : 'N/A : '} :` : 'N/A : '}
              <span class="flex items-center">
                {/* <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                  </path>
                </svg> */}
                {this.state.week ? this.state.week[1] : ' N/A'}
              </span>
            </div>
          </div>
          <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <p>
              This Month
            </p>
            <div class="flex items-end text-xs">
              {this.state.month ? `${this.state.month[0] ? this.state.month[0] : 'N/A : '} :` : 'N/A : '}
              <span class="flex items-center">
                {/* <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                  </path>
                </svg> */}
                {this.state.month ? this.state.month[1] : ' N/A'}
              </span>
            </div>
          </div>
          <div class="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <p>
              This Year
            </p>
            <div class="flex items-end text-xs">
              {this.state.year ? `${this.state.year[0] ? this.state.year[0] : 'N/A : '} : ` : 'N/A : '}
              <span class="flex items-center">
                {/* <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                  </path>
                </svg> */}
                {this.state.year ? ` ${this.state.year[1]}` : ' N/A'}
              </span>
            </div>
          </div>

          <div class="flex items-center text-sm space-x-14 md:space-x-26 justify-between">
            <p>
              All Time
            </p>
            <div class="flex items-end text-xs">
              {this.state.all ? `${this.state.all[0] ? this.state.all[0] : 'N/A : '} : ` : 'N/A : '}
              <span class="flex items-center">
                {/* <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                  </path>
                </svg> */}
                {this.state.all ? ` ${this.state.all[1]}` : ' N/A'}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SKUInfoCard;