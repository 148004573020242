import axios from "axios";
import * as env from "../config/env";
const baseUrl = `${env.url}/stats/op`;
const OP_URL = `${env.OpBarcodeUrl}`;
const OP_USER_TOKEN = `${env.OP_USER_TOKEN}`;

const headers = {
  "User-Token": env.userToken,
};

const opHeaders = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'OP-USER-TOKEN': OP_USER_TOKEN
}

let reprintServer = 'http://localhost:9000/api/orders';

export function getOrders() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/orders`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getOrderItems() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/orderItems`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getOrdersBySku() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/ordersBySku`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getCanceledOrders() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/canceled`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getOrdersDueToday() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/dueToday?day=${"today"}&facility=${"fm"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getOrdersDueTmrw() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/dueToday?day=${"tmrw"}&facility=${"fm"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getItemsDueToday() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/itemsDue?day=${"today"}&facility=${"fm"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getItemsDueTmrw() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/itemsDue?day=${"tmrw"}&facility=${"fm"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getLateOrders() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/lateOrders?facility=${"fm"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getLateItems() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/lateItems?facility=${"fm"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getInventory() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/inventory`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getOrdersShipped() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/ordersShipped?facility=${"fm"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getItemsShipped() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/itemsShipped?facility=${"fm"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getArtwork(barcode) {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/artwork?barcode=${barcode}&token=${OP_USER_TOKEN}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getShipErrors() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/shipError`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getOrderCycle() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/cycle`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getMixedOrderCycle(data) {
  const config = {
    method: "POST",
    headers,
    data
  };
  return axios.post(`${baseUrl}/mixedCycle`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getPrelimLines(timeFrame) {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/lines?time=${timeFrame}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getPopularLocation(timeFrame, location) {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/locations?time=${timeFrame}&location=${location}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getSkuStats() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/skuStats`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getSkuShipped(data) {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/skuShipped?time=${data.time}&sku=${data.sku}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getShippingLines(timeFrame) {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/shippingLines?time=${timeFrame}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getPrelimItemLines(timeFrame) {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/itemLines?time=${timeFrame}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

// export function getArtworkLocal(barcode) {
//   const config = {
//     method: "GET",
//     opHeaders
//   };
//   return axios.get(`${reprintServer}/${barcode}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
// }

export function getSplomSetup() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/splomSetup`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getDailyReport() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/dailyReport?facility=${"fm"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getPopularSku(timeFrame) {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/popularSku?time=${timeFrame}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getCompanySku(timeFrame, company) {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/companySku?time=${timeFrame}&company=${company}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getRevenue(timeFrame) {
  const config = {
    method: "GET",
    headers
  }
  return axios.get(`${baseUrl}/revenue?time=${timeFrame}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getFm_Ky() {
  const config = {
    method: "GET",
    headers
  }
  return axios.get(`${baseUrl}/FmKy`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

const responseSuccessHandler = response => {
  return response.data;
};
  
const responseErrorHandler = error => {
  console.log(error);
  return Promise.reject(error);
};