import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "./Modal";
import * as opOrderStatsService from "../services/openprintStats.service";
import { IoIosRefresh } from "react-icons/io";

class OrdersDue extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      readyTmrw: null,
      ready: null,
      tmrwObj: {},
      printedObj: {},
      itemMap: {}
    }
  }

  componentDidMount() {
    opOrderStatsService.getOrdersDueToday().then(response => {
      //console.log(response);
      let numReady = response.dueNum;
      let due = JSON.parse(response.due);
      let itemIds = JSON.parse(response.itemMap);
      //console.log("Entire Queue");
      //console.log("Item Map");
      //console.log(itemIds);
      //console.log(itemIds.data);
      this.setState({ 
        ready: numReady,
        printedObj: due,
        itemMap: itemIds.data
      });

    }).catch(err => {
      console.log(err);
    })

    opOrderStatsService.getOrdersDueTmrw().then(response => {
      //console.log(response);
      let numReady = response.dueNum;
      let due = JSON.parse(response.due);
      let itemIds = JSON.parse(response.itemMap);
      //console.log("Entire Queue");
      //console.log(due);
      //console.log(itemIds.data);
      this.setState({ 
        readyTmrw: numReady,
        tmrwObj: due,
        itemMap: itemIds.data
      });

    }).catch(err => {
      console.log(err);
    });

    
  }

  refresh = () => {
    this.componentDidMount();
    return;
  }

  render() {
    return (      
      <div className="shadow-lg ml-0.5 mt-1 px-4 py-6 w-full bg-gray-800 relative grow">
        <span className="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          Orders Due Today <Modal data={this.state.printedObj} numCols={4} fullArt={true} itemIds={this.state.itemMap} system={'OP'} />
        </span>
        <button onClick={this.refresh} className="float-right text-md text-white">
          <IoIosRefresh className="hover:text-green-700" />
        </button>
        <div className="flex items-end space-x-2 my-6">
          <p className="text-5xl text-white dark:text-white font-bold">
            {this.state.ready}
          </p>
        </div>
        <hr className="mb-5" />
        <span className="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          Orders Due Next Business Day <Modal data={this.state.tmrwObj} numCols={4} fullArt={true} itemIds={this.state.itemMap} system={'OP'} />
        </span>
        <div className="flex items-end space-x-2 my-6">
          <p className="text-5xl text-white dark:text-white font-bold">
            {this.state.readyTmrw}
          </p>
        </div>
      </div>
    )
  }
}

export default OrdersDue;