import React from "react";
import { lineConverter } from "./helpers/skuToLineOp"; 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../images.css';
import * as opOrderStatsService from "../services/openprintStats.service";
import * as ipOrderStatsService from "../services/iprintStats.service";
import * as spOrderStatsService from "../services/subprintStats.service";

export default function Modal(props) {
  const [showModal, setShowModal] = React.useState(false);
  //console.log(props.data.columns);
  //console.log(props.data.data);
  //console.log(props.art);
  //console.log(props);
  // if (props.fullArt) {
  //   console.log(props.itemIds);
  //   console.log(props.fullArt);
  // }
  const [query, setQuery] = React.useState("")
  const [showImage, setShowImage] = React.useState({});
  const [art, setArt] = React.useState([]);
  //const [artColumns, setArtColumns] = React.useState({})

  const toggleImage = (str) => {
    let copyObj = {
      ...showImage
    };
    if (copyObj[str]) {
      copyObj[str] = false;
      if (props.fullArt) {
        setArt([]);
      }
    } else {
      copyObj[str] = true;
      if (props.fullArt) {
        findArtwork(str);
      }
    }
    setShowImage(copyObj);
  }

  const findArtwork = async (orderId) => {
    //console.log("Enter findArtwork");
    //console.log(`Getting Artwork: ${orderId}`);
    for (let i=0; i < props.itemIds.length; i++) {
      let currRow = props.itemIds[i];
      let currId = currRow[0];
      if (currId === orderId) {
        //console.log(currId);
        //console.log(currRow[1]);
        
        let imgArr = [];
        let artArr = await grabArtworks(currRow[1]);
        // console.log(artArr);
        // for (let j=0; j < artArr.artworks.length; j++) {
        //   imgArr.push(<img key={artArr.artworks[j].barcode_id} className="thumbnail zoom" src={artArr.artworks[j].artwork_path} style={{width: '95%', height: '95%', border: '.5px solid #555'}} />)
        // }
        //console.log(imgArr);
        
        imgArr.push(
          <div onClick={() => toggleImage(orderId)}>
            <div
              className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-x-0 inset-y-0 z-100 max-h-screen w-full max-w-screen outline-none focus:outline-none"
            >
              <div onClick={e => e.stopPropagation()} className="modal-dialog relative w-auto mx-auto max-w-5xl">
                {/*content*/}
                <div className="bg-slate-500 border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex bg-slate-500 items-start justify-between p-2 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-2xl text-white font-semibold">
                      Order Artworks: {orderId}
                    </h3>
                    {/* <button
                      className="mb-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => toggleImage(orderId)}
                    >
                      <span className="text-white h-6 w-6 text-2xl block outline-none focus:outline-none">
                        x
                      </span>
                    </button> */}
                  </div>
                  <div className="relative text-center overflow-x-auto overflow-y-auto shadow-md sm:rounded-lg pt-12 pl-12 pb-8 pr-4">
                    {artArr.artworks.map( (obj, index) => {
                      return (
                        <img className="thumbnail zoom_2 mt-12" src={obj.artwork_path} style={{width: '95%', height: '95%', border: '.5px solid #555'}} />
                      )
                    })}
                  </div>
                  {/*footer*/}
                  {/* <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => toggleImage(orderId)}
                    >
                      Close
                    </button>
                  </div> */}
                </div>
              </div>
              {/* <div className="opacity-5 fixed inset-0 z-90 bg-black"></div> */}
            </div>
          </div>
        )
        setArt(imgArr);
          
        
      }
    }
    
    
    //return;
  }

  const grabArtworks = (itemId) => {
    let service;
    if (props.system === 'IP') {
      service = ipOrderStatsService
    } else if (props.system === 'OP') {
      service = opOrderStatsService
    } else if (props.system === 'SP') {
      service = spOrderStatsService
    }
    if (service) {
      return service.getArtwork(itemId).catch(err => {
        console.log(err);
      });
    }
    
  }
  
  const displayTotals = (totalObj) => {
    if (!(totalObj)) {
      return;
    }
    //console.log(totalObj);
    let columns = [];
    let values = [];
    let keys = Object.keys(totalObj);
    for (let i = 0; i < keys.length; i++) {
      let currKey = keys[i];
      columns.push(currKey);
      values.push(totalObj[currKey])
    }
    //console.log(columns);
    //console.log(values);
    return (
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400" >
          <tr>
            {columns.map((column, index) => {
              <th key={index} scope="col" className="px-4 py-2">
                {column}
              </th>
            })}
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" >
            {values.map((value, index) => {
              <th key={index} className="px-4 py-2">
                {value}
              </th>
            })}
          </tr>  
        </tbody>
      </table>
    );
  }

  const copyItem = (item) => {
    navigator.clipboard.writeText(item);
    toast(`Copied ${item} to Clipboard!`)
    return;
  }
  
  return (
    <>
      <button
        className="bg-gray-500 text-white active:bg-gray-600 uppercase text-xs px-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        View
      </button>
      {showModal ? (
        <div onClick={() => setShowModal(false)}>
          <div
            className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-x-0 inset-y-0 z-50 max-h-screen w-full max-w-screen outline-none focus:outline-none"
          >
            <div onClick={e => e.stopPropagation()} className=" relative w-auto mx-auto max-w-7xl">
              {/*content*/}
              <div className="bg-gray-800 border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
                {/*header*/}
                <div className="flex bg-gray-800 items-start justify-between p-2 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-2xl text-white font-semibold">
                    {props.shippingErrors ? "Shipping Errors" : "Order Info"}
                  </h3>
                  <div className="ml-auto float-right">
                    <label className="ml-auto text-gray-400">
                      Search: 
                      <input className="ml-4 shadow appearance-none border rounded w-36 py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="search" value={query} onChange={event => setQuery(event.target.value)} />
                    </label> 
                  </div>
                  
                  
                  <button
                    className="mb-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-white h-6 w-6 text-2xl block outline-none focus:outline-none">
                      x
                    </span>
                  </button>
                </div>
                {/*body*/}
                
                <div className="relative flex overflow-x-auto overflow-y-auto shadow-md sm:rounded-lg">
                  
                  <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400" >
                      
                      <tr>
                        {props.index ? 
                          <th className="px-4 py-2">
                            SKU
                          </th> : null}
                        {props.data.columns.map((col, index) => {
                          return (index < props.numCols ? (
                            <th key={index} scope="col" className="px-4 py-2">
                              {col}
                            </th>
                          ) : null)
                        })}
                        {props.dept ? 
                          <th scope="col" className="px-4 py-2">
                            Line
                          </th> : null}
                        
                        {(props.art || props.fullArt) ? 
                          <th scope="col" className="px-4 py-2">
                            Artwork
                          </th> : null}
                      </tr>
                    </thead>
                    <tbody>
                      {props.data.data.filter(row => {
                        if (query === "") {
                          return row;
                        }
                        for (let i=0; i < row.length; i++) {
                          if (String(row[i]).toLowerCase().includes(query.toLowerCase())) {
                            return row;
                          }
                        }
                      }).map((row, index) => 
                        ( 
                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                            {props.index ? 
                              <th className="px-4 py-2" onClick={() => copyItem(props.data.index[index])}>
                                {props.data.index[index]}
                              </th> : null}
                            {row.map((item, index) => {
                              return (index < props.numCols ? (
                                <th key={index} className="px-6 py-4" onClick={() => copyItem(item)}>
                                  {item}
                                </th>
                              ) : null)
                            })}
                            {props.dept ? 
                              <th className="px-4 py-2" onClick={() => props.index ?  copyItem(lineConverter[props.data.index[index]]) : copyItem(lineConverter[row[2]])}>
                                {props.index ? lineConverter[props.data.index[index]] : lineConverter[row[2]]}
                              </th> : null}

                            {props.art ? 
                              <th scope="col" className={showImage[row[6]] ? "pl-4 pr-20 pb-2 pt-10" : "py-2 px-4"} onClick={() => toggleImage(row[6])}>
                                {showImage[row[6]] ? <img className="thumbnail zoom" src={row[6]} style={{width: '95%', height: '95%', border: '.5px solid #555'}} /> : <button onClick={() => toggleImage(row[6])}  className="bg-gray-500 text-white active:bg-gray-600 uppercase text-md px-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">View</button>}
                              </th> : null}

                            {props.fullArt ? 
                              <th scope="col" className={showImage[row[0]] ? "pl-4 pr-20 pb-2 pt-10" : "py-2 px-4"} onClick={() => toggleImage(row[0])}>
                                {showImage[row[0]] ? art : <button onClick={() => toggleImage(row[0])}  className="bg-gray-500 text-white active:bg-gray-600 uppercase text-md px-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">View</button>}
                              </th> : null}
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      ) : null}
    </>
  );
}

class TableRow extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    var row = this.props.row;
    console.log(row);
    return (
        <tr>
            {row.map(val => <td>{val}</td>)}
        </tr>
    )
  }
}
