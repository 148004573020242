import React from "react";
import Modal from "../Modal";
import { lineConverter } from "../helpers/skuToLineOp";
import * as ipOrderStatsService from "../../services/iprintStats.service";
import { IoIosRefresh } from "react-icons/io";

class IpSkuCard extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        allQueue: null,
        totalInQueue: {},
        notComposited: {},
        notDownloaded: {},
        notPrinted: {},
        ready: {},
        entireObj: {},
        needsCompositeObj: {},
        needsDownloadObj: {},
        downloadedObj: {},
        printedObj: {}
      }
    }
  
    componentDidMount() {
      ipOrderStatsService.getOrdersBySku().then(response => {
        //console.log(response);
        
        let queues = response.queues;
        let entireQueue = JSON.parse(queues.Queue);
        let needsComposite = JSON.parse(queues.toComposite);
        let needsDownload = JSON.parse(queues.toDownload);
        let downloaded = JSON.parse(queues.downloaded);
        let printed = JSON.parse(queues.printed);
        //console.log(printed);
        let entireAcc = this.calculateByLine(entireQueue.index, entireQueue.data);
        let needsCompositeAcc = this.calculateByLine(needsComposite.index, needsComposite.data);
        let needsDownloadAcc = this.calculateByLine(needsDownload.index, needsDownload.data);
        let downloadedAcc = this.calculateByLine(downloaded.index, downloaded.data);
        let printedAcc = this.calculateByLine(printed.index, printed.data);
        this.setState({ 
          totalInQueue: entireAcc,
          notComposited: needsCompositeAcc,
          notDownloaded: needsDownloadAcc,
          notPrinted: downloadedAcc,
          ready: printedAcc,
          entireObj: entireQueue,
          needsCompositeObj: needsComposite,
          needsDownloadObj: needsDownload,
          downloadedObj: downloaded,
          printedObj: printed
        });
      }).catch(err => {
        console.log(err);
      })
    }

    calculateByLine = (skus, quantities) => {
      let lineObj = {};
      skus.map((sku, index) => {
        let line = lineConverter[sku];
        if (lineObj[line]) {
          lineObj[line] += quantities[index][0];
        } else {
          lineObj[line] = quantities[index][0];
        }
      })
      return lineObj;
    }

    refresh = () => {
      //console.log("REFRESH");
      this.componentDidMount();
      return;
    }
  
    render() {
      return (      
        <div className="shadow-lg ml-0.5 mt-1 px-4 py-4 w-full bg-slate-700 dark:bg-gray-800 relative">
          <span className="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
            Items Ready By SKU <Modal data={this.state.printedObj} numCols={1} index={'SKU'} dept={true}  />
          </span>
          <button onClick={this.refresh} className="float-right text-md text-white">
            <IoIosRefresh className="hover:text-green-700" />
          </button>
          <div className="flex items-end space-x-2 my-1 mb-2">
            <div className="flex">
              <p className="text-sm text-white dark:text-white font-bold">
                Mugs: {this.state.ready['Mug'] ? this.state.ready['Mug'] : 0}
              </p>
            </div>
            <div className="flex">
              <p className="text-sm text-white dark:text-white font-bold">
                Posters: {this.state.ready['Poster'] ? this.state.ready['Poster'] : 0}
              </p>
            </div>
            <div className="flex">
              <p className="text-sm text-white dark:text-white font-bold">
                Press: {this.state.ready['Press'] ? this.state.ready['Press'] : 0}
              </p>
            </div>
          </div>
          <div className="text-white">
            <div className="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
              <span>
                Entire Queue <Modal data={this.state.entireObj} numCols={1} index={'SKU'} dept={true} />
              </span>
              <div className="flex items-end text-xs space-x-2 my-1">
                <div className="flex">
                  <p className="text-white dark:text-white font-bold">
                    Mugs: {this.state.totalInQueue['Mug'] ? this.state.totalInQueue['Mug'] : 0}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-white dark:text-white font-bold">
                    Posters: {this.state.totalInQueue['Poster'] ? this.state.totalInQueue['Poster'] : 0}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-white dark:text-white font-bold">
                    Press: {this.state.totalInQueue['Press'] ? this.state.totalInQueue['Press'] : 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
              <span>
                Accepted <Modal data={this.state.needsCompositeObj} numCols={1} index={'SKU'} dept={true} />
              </span>
              <div className="flex items-end text-xs space-x-2 my-1">
                <div className="flex">
                  <p className="text-white dark:text-white font-bold">
                    Mugs: {this.state.notComposited['Mug'] ? this.state.notComposited['Mug'] : 0}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-white dark:text-white font-bold">
                    Posters: {this.state.notComposited['Poster'] ? this.state.notComposited['Poster'] : 0}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-white dark:text-white font-bold">
                    Press: {this.state.notComposited['Press'] ? this.state.notComposited['Press'] : 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
              <span>
                Predownload <Modal data={this.state.needsDownloadObj} numCols={1} index={'SKU'} dept={true}/>
              </span>
              <div className="flex items-end text-xs space-x-2 my-1">
                <div className="flex">
                  <p className="text-white dark:text-white font-bold">
                    Mugs: {this.state.notDownloaded['Mug'] ? this.state.notDownloaded['Mug'] : 0}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-white dark:text-white font-bold">
                    Posters: {this.state.notDownloaded['Poster'] ? this.state.notDownloaded['Poster'] : 0}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-white dark:text-white font-bold">
                    Press: {this.state.notDownloaded['Press'] ? this.state.notDownloaded['Press'] : 0}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center text-sm space-x-12 md:space-x-24 justify-between">
              <span>
                Not Printed <Modal data={this.state.downloadedObj} numCols={1} index={'SKU'} dept={true} />
              </span>
              <div className="flex items-end text-xs space-x-2 my-1">
                <div className="flex">
                  <p className="text-white dark:text-white font-bold">
                    Mugs: {this.state.notPrinted['Mug'] ? this.state.notPrinted['Mug'] : 0}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-white dark:text-white font-bold">
                    Posters: {this.state.notPrinted['Poster'] ? this.state.notPrinted['Poster'] : 0}
                  </p>
                </div>
                <div className="flex">
                  <p className="text-white dark:text-white font-bold">
                    Press: {this.state.notPrinted['Press'] ? this.state.notPrinted['Press'] : 0}
                  </p>
                </div>
              </div>
          </div>
        </div>
      </div>
    )
  }
}
  
export default IpSkuCard;