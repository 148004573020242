//const environment = "dev"
const environment = "stage"
//const environment = "prod"
//export url;
export const userToken = "GOJDUPH6";
export const secretToken = "TSEIHWZ2";
export const OpBarcodeUrl = "https://portal.printverse.com/apis/get_artwork_by_barcode";
export const OP_USER_TOKEN = "fH57Jti";
export var url;
if (environment === "stage") {
  url = "https://stage.printversepro.com"
} else if (environment === "prod") {
  url = "http://localhost:5000"
} else {
  url = "http://localhost:5000"
}

// 5U3EWHTZ
// 4RTFN7R0
// GOJDUPH6
// JQDBF70S
// 3T8VXY88
// MN8XADQM
// 3JY12G2E
// 1KAWF25B
// TSEIHWZ2
// FODH3PU2
//export const url = "http://localhost:8082/admin";
//export const railsUrl = "http://localhost:3000";


// dev
//export const railsUrl = "http://13.57.231.51";

//production
// export const url = "https://admin.printversepro.com/admin";
// export const railsUrl = "https://services.printversepro.com";
