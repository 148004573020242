import axios from "axios";
import * as env from "../config/env";
const baseUrl = `${env.url}/stats/auth`;
const headers = {
  "User-Token": env.userToken,
};

const specialHeaders = {
  "Secret-Token": env.secretToken,
}

export function getLogin(data) {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/login?email=${data.email}&password=${data.pass}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function saveLogin(data) {
  const config = {
    method: "POST",
    specialHeaders,
    data
  };
  return axios.post(`${baseUrl}/login`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

const responseSuccessHandler = response => {
  return response.data;
};
  
const responseErrorHandler = error => {
  console.log(error);
  return Promise.reject(error);
};