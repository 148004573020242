import React from "react";
//import Modal from "./Modal";
import * as graphicStatsService from "../../services/graphicStats.service";
import { IoIosRefresh } from "react-icons/io";

class PaperStatus extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      printerMap: {
        "EPSON SureColor P9500-1": "P9500-1",
        //"EPSON SureColor P9500-2": "P9500-2",
        "EPSONP10000-1": "10000-1",
        "EPSONP10000-2": "10000-2",
        "EPSONP10000-3": "10000-3",
      }, 
      reversePrinterMap: {
        "P9500-1": "EPSON SureColor P9500-1" ,
        //"EPSON SureColor P9500-2": "P9500-2",
        "10000-1": "EPSONP10000-1" ,
        "10000-2": "EPSONP10000-2",
        "10000-3": "EPSONP10000-3",
      },
      name: null,
      status: null,
      paperLength: 'N/A',
      ws: null,
      pc: null,
      alias: null
    }
  }
  
  timeout = 250;

  componentDidMount() {
    let name = this.props.name.split('_')[1]
    //console.log(this.props.name);
    //console.log('Initial Name');
    //console.log(name);
    //console.log(this.state.reversePrinterMap);
    //console.log(Object.keys(this.state.reversePrinterMap));
    let printerName = this.state.reversePrinterMap[name];
    //console.log(printerName);
    //console.log(this.props.pc);
    let data = {
      "name": printerName,
      "pc_name": this.props.pc
    };
    graphicStatsService.getPaperStatus(data).then(response => {
      //console.log(response);
      //console.log(Object.keys(response));
      //console.log(response.item);
      //console.log(response.item[0]);
      let length = (Number(response.item[0].roll_length) / 12).toFixed(2);
      let status;
      if (length > 60) {
        status = 'OK';
      } else if (length > 1.5) {
        status = 'LOW';
      } else {
        status = 'OUT';
      }
      this.setState({
        "status": status,
        "paperLength": length,
        //"alias": printer,
        //"pc": pc
      });
    }).catch(err => {
      console.log(err);
    })
    this.setState({
      "name": name,
      //"status": 'OUT',
      //"paperLength": 100 
    });
    this.connect();
  }

  connect = () => {
    let ws = new WebSocket("wss://stage.printversepro.com/ws");
    let that = this;
    let connectInterval;
    ws.onopen = () => {
      console.log("Connected websocket to component");

      this.setState({ 
        ws: ws 
      });
      
      //if (that.state.alias && that.state.pc) {
        // data = {
        //   printer_name: that.state.name,
        //   alias: that.state.alias,
        //   pc_name: that.state.pc,
        //   type: 'GET_Roll_Length'
        // }
      // } else {
      //   data = {
      //     printer_name: that.state.name,
      //     type: 'GET_Roll_Length'
      //   }
      // }
      //ws.send(JSON.stringify(data));

      that.timeout = 500; // reset timer to 500 on open of websocket connection 
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    };

    ws.onclose = e => {
      console.log(
          `Socket is closed. Reconnect will be attempted in ${Math.min(
              10000 / 1000,
              (that.timeout + that.timeout) / 1000
          )} second.`,
          e.reason
      );

      that.timeout = that.timeout + that.timeout; //increment retry interval
      connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
    };

    ws.onmessage = function (event) {
      console.log(event);
      const json = JSON.parse(event.data);
      let printer = json['printer_name'];
      let pc = json['pc_name'];
      let length = json['length'];
      let currName = that.state.printerMap[printer];
      if (currName === that.state.name) {
        length = (Number(length) / 12).toFixed(2);
        let status;
        if (length > 60) {
          status = 'OK';
        } else if (length > 1.5) {
          status = 'LOW';
        } else {
          status = 'OUT';
        }
        that.setState({
          "status": status,
          "paperLength": length,
          "alias": printer,
          "pc": pc
        });
      }
    };
  }

  check = () => {
    //const { ws } = this.state;
    if (!this.state.ws || this.state.ws.readyState == WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
  };

  

  bgColor = (status) => {
    if (status === 'LOW') {
      return "bg-amber-400";
    } else if (status === 'OUT') {
      return "bg-red-700";
    } else if (status === 'OK') {
      return "bg-green-600";
    }
    return "bg-slate-600";
  }

  replacePaper = () => {
    let name = this.props.name.split('_')[1];
    //console.log(name);
    let data = {
      "printer_name": this.state.reversePrinterMap[name],
      "pc_name": this.props.pc,
      "roll_length": 2100
    } 
    //console.log(data);
    graphicStatsService.resetPaper(data).then(response => {
      console.log(response);
      this.setState({
        "status": 'OK',
        "paperLength": 175,
        //"alias": printer,
        //"pc": pc
      });
    }).catch(err => {
      console.log(err);
    })
  }

  refresh = () => {
    this.componentDidMount();
    return;
  }

  render() {
    return (      
      <div className={"shadow-lg ml-0.5 mt-1 px-4 py-6 w-full relative grow " + this.bgColor(this.state.status)}>
        <span className="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          {this.props.name}
        </span>
        <button onClick={this.refresh} className="float-right text-md text-white">
          <IoIosRefresh className="hover:text-green-700" />
        </button>
        <div className="flex items-end space-x-2 my-6">
          <p className="text-5xl text-white dark:text-white font-bold">
            {this.state.status ? this.state.status : 'N/A'}
          </p>
        </div>
        <div className="text-white">
          <div className="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <span>
              Paper Length 
            </span>
            <div className="flex items-end text-xs">
              {this.state.paperLength === 'N/A' ? this.state.paperLength : `${this.state.paperLength}ft` }
            </div>
          </div>
          {this.state.status == 'OUT' ?
            <div className="flex items-center pb-2 mb-2 text-sm">
              <span>
                <button onClick={this.replacePaper} className="bg-emerald-500 hover:bg-emerald-700 font-bold py-2 px-4 rounded">Paper Replaced</button>
              </span>
            </div> : null}
        </div>
      </div>
    )
  }
}

export default PaperStatus;