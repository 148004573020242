import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "./Modal";
import * as opOrderStatsService from "../services/openprintStats.service";
import { IoIosRefresh } from "react-icons/io";

class OpInventory extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      ready: null,
      inventoryObj: {},
      mug_1108: null,
      mug_1567: null,
      mug_1504: null,
      mug_1132: null,
      mug_1111: null,
      mug_1511: null,
      mug_1146: null
    }
  }

  componentDidMount() {
    opOrderStatsService.getInventory().then(response => {
      let inventory = JSON.parse(response.inventory);
      this.setState({ 
        inventoryObj: inventory,
        mug_1108: inventory.data[13][3],
        mug_1111: inventory.data[16][3],
        mug_1132: inventory.data[37][3],
        mug_1146: inventory.data[49][3],
        mug_1504: inventory.data[70][3],
        mug_1511: inventory.data[71][3],
        mug_1567: inventory.data[93][3],
      });
    }).catch(err => {
      console.log(err);
    })
  }

  refresh = () => {
    this.componentDidMount();
    return;
  }

  render() {
    return (      
      <div className="shadow-lg ml-0.5 mt-1 px-4 py-6 w-full bg-gray-800 relative grow">
        <span className="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          Inventory <Modal data={this.state.inventoryObj} numCols={5} />
        </span>
        <button onClick={this.refresh} className="float-right text-md text-white">
          <IoIosRefresh className="hover:text-green-700" />
        </button>
        <div class="text-white">
          <div class="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <span>
              21108
            </span>
            <div class="flex items-end text-xs">
              {this.state.mug_1108}
            </div>
          </div>
          <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <span>
              21111
            </span>
            <div class="flex items-end text-xs">
              {this.state.mug_1111}
            </div>
          </div>
          <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <span>
              21132
            </span>
            <div class="flex items-end text-xs">
              {this.state.mug_1132}
            </div>
          </div>
          <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <span>
              21146
            </span>
            <div class="flex items-end text-xs">
              {this.state.mug_1146}
            </div>
          </div>
          <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <span>
              21504
            </span>
            <div class="flex items-end text-xs">
              {this.state.mug_1504}
            </div>
          </div>
          <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <span>
              21511
            </span>
            <div class="flex items-end text-xs">
              {this.state.mug_1511}
            </div>
          </div>
          <div class="flex items-center text-sm space-x-12 md:space-x-24 justify-between">
            <span>
              21567
            </span>
            <div class="flex items-end text-xs">
              {this.state.mug_1567}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OpInventory;