import axios from "axios";
import * as env from "../config/env";
const baseUrl = `${env.url}/stats/ip`;
const OP_USER_TOKEN = `${env.OP_USER_TOKEN}`;
const headers = {
  "User-Token": env.userToken,
};

export function getOrders() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/orders`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getOrderItems() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/orderItems`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getOrdersDueToday() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/dueToday?day=${"today"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}
  
export function getOrdersDueTmrw() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/dueToday?day=${"tmrw"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}
  
export function getItemsDueToday() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/itemsDue?day=${"today"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}
  
export function getItemsDueTmrw() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/itemsDue?day=${"tmrw"}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}
  
export function getLateOrders() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/lateOrders`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}
  
export function getLateItems() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/lateItems`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}
  
export function getOrdersShipped() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/ordersShipped`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}
  
export function getItemsShipped() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/itemsShipped`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}
  
export function getOrdersBySku() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/ordersBySku`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getCanceledOrders() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/canceled`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getShipErrors() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/shipError`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getOrderCycle() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/cycle`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getPrelimLines(timeFrame) {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/lines?time=${timeFrame}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getArtwork(barcode) {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/artwork?barcode=${barcode}&token=${OP_USER_TOKEN}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getInventory() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/inventory`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

const responseSuccessHandler = response => {
  return response.data;
};
    
const responseErrorHandler = error => {
  console.log(error);
  return Promise.reject(error);
};