import React from "react";
import Modal from "./Modal";
import * as opOrderStatsService from "../services/openprintStats.service";
import { IoIosRefresh } from "react-icons/io";

class OpCancelCard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      today: null,
      week: null,
      month: null,
      year: null,
      lastYear: null,
      todayObj: null,
      weekObj: null,
      monthObj: null,
      yearObj: null
    }
  }
  
  componentDidMount() {
    opOrderStatsService.getCanceledOrders().then(response => {
      //console.log(response);
      let myTodayOrders = response.numShipped.today;
      let myWeekOrders = response.numShipped.week;
      let myMonthOrders = response.numShipped.month;
      let myYearOrders = response.numShipped.year;
      let myLastYearOrders = response.numShipped.lastYear;

      let dataToday = JSON.parse(response.data.today);
      let dataWeek = JSON.parse(response.data.week);
      let dataMonth = JSON.parse(response.data.month);
      let dataYear = JSON.parse(response.data.year);

      this.setState({ 
        today: myTodayOrders,
        week: myWeekOrders,
        month: myMonthOrders,
        year: myYearOrders,
        lastYear: myLastYearOrders,
        todayObj: dataToday,
        weekObj: dataWeek,
        monthObj: dataMonth,
        yearObj: dataYear
      });
    }).catch(err => {
      console.log(err);
    })
  }

  refresh = () => {
    //console.log("REFRESH");
    this.componentDidMount();
    return;
  }

  render() {
    return (
      <div class="shadow-lg ml-0.5 mt-1 px-4 py-7 w-full bg-slate-700 dark:bg-gray-800 relative">
        <span class="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
            Orders Canceled Today <Modal data={this.state.todayObj} numCols={4} />
        </span>
        <button onClick={this.refresh} className="float-right text-md text-white">
          <IoIosRefresh className="hover:text-green-700" />
        </button>
        <div class="flex items-end space-x-2 my-6">
          <p class="text-5xl text-white dark:text-white font-bold">
            {this.state.today}
          </p>
        </div>
        <div class="text-white">
          <div class="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <span>
              This Week <Modal data={this.state.weekObj} numCols={4} />
            </span>
            <div class="flex items-end text-xs">
              {this.state.week}
            </div>
          </div>
          <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <span>
              This Month <Modal data={this.state.monthObj} numCols={4} />
            </span>
            <div class="flex items-end text-xs">
              {this.state.month}
            </div>
          </div>
          <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <span>
              This Year <Modal data={this.state.yearObj} numCols={4} />
            </span>
            <div class="flex items-end text-xs">
              {this.state.year}
            </div>
          </div>
          <div class="flex items-center text-sm space-x-12 md:space-x-24 justify-between">
            <span>
              Last Year
            </span>
            <div class="flex items-end text-xs">
              {this.state.lastYear}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OpCancelCard;