import React from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as orderStatsService from "../services/orderStats.service";

class OrderTextCard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      today: null,
      week: null,
      month: null,
      year: null,
      lastYear: null,
      all: null,
      allOrders: null,
      columns: null,
      index: null,
    }
  }

  componentDidMount() {
    orderStatsService.getOrders().then(response => {
      //console.log(response);

      let myData = response.allOrders.data;
      let myIndex = response.allOrders.index;
      let myColumns = response.allOrders.columns;

      let myTodayOrders = response.numOrders.ordersToday;
      let myWeekOrders = response.numOrders.ordersWeek;
      let myMonthOrders = response.numOrders.ordersMonth;
      let myYearOrders = response.numOrders.ordersYear;
      let allMyOrders = response.numOrders.ordersTotal;
      let myLastYearOrders = response.numOrders.ordersLastYear;

      this.setState({ 
        allOrders: myData,
        columns: myColumns,
        index: myIndex,
        today: myTodayOrders,
        week: myWeekOrders,
        month: myMonthOrders,
        year: myYearOrders,
        all: allMyOrders,
        lastYear: myLastYearOrders,
      });
    }).catch(err => {
      console.log(err);
    })
  }

  render() {
    return (      
      <div class="shadow-lg ml-0.5 mt-1 px-4 py-6 w-full bg-gray-800 relative grow">
        <p class="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          Orders Today
        </p>
        <div class="flex items-end space-x-2 my-6">
          <p class="text-5xl text-white dark:text-white font-bold">
            {this.state.today}
          </p>
          {/* <span class="text-green-500 text-xl font-bold flex items-center">
            <svg width="20" fill="currentColor" height="20" class="h-3" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
              <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
              </path>
            </svg>
            22%
          </span> */}
        </div>
        <div class="text-white">
          <div class="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <p>
              Orders This Week  
            </p>
            <div class="flex items-end text-xs">
              {this.state.week}
              {/* <span class="flex items-center">
                <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                  </path>
                </svg>
                22%
              </span> */}
            </div>
          </div>
          <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <p>
              Orders This Month
            </p>
            <div class="flex items-end text-xs">
              {this.state.month}
              {/* <span class="flex items-center">
                <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                  </path>
                </svg>
                12%
              </span> */}
            </div>
          </div>
          <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <p>
              Orders This Year
            </p>
            <div class="flex items-end text-xs">
              {this.state.year}
              {/* <span class="flex items-center">
                <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                  </path>
                </svg>
                12%
              </span> */}
            </div>
          </div>
          <div class="flex items-center text-sm space-x-12 md:space-x-24 justify-between">
            <p>
              Orders Last Year
            </p>
            <div class="flex items-end text-xs">
              {this.state.lastYear}
              {/* <span class="flex items-center">
                <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                  </path>
                </svg>
                41%
              </span> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OrderTextCard;
