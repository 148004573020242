import React from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "./Modal";
import SkuModal from "./SkuTotalModal";
import { lineConverter } from "./helpers/skuToLineOp";
import * as orderStatsService from "../services/orderStats.service";
import * as opOrderStatsService from "../services/openprintStats.service";
import * as spOrderStatsService from "../services/subprintStats.service";
import * as ipOrderStatsService from "../services/iprintStats.service";
import * as kyOrderStatsService from "../services/kentuckyStats.service"; 
import { IoIosRefresh } from "react-icons/io";

class DailyReport extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      spin: false,
      dailyNum: null,
      dailyReport: {}, 
      lines: {},
      skus: {},
      dailyNumYesterday: null,
      dailyReportYesterday: {}, 
      linesYesterday: {},
      skusYesterday: {}
    }
  }

  componentDidMount() {
    this.setState({
      spin: true,
      dailyNum: null,
      dailyReport: {}, 
      lines: {},
      skus: {},
      dailyNumYesterday: null,
      dailyReportYesterday: {}, 
      linesYesterday: {},
      skusYesterday: {}
    });
    let service;
    if (this.props.system === 'PV') {
      service = orderStatsService;
    } else if (this.props.system === 'OP') {
      service = opOrderStatsService;
    } else if (this.props.system === 'SP') {
      service = spOrderStatsService;
    } else if (this.props.system === 'IP') {
      service = ipOrderStatsService;
    } else if (this.props.system === 'KY') {
      service = kyOrderStatsService;
    }
    
    if (service) {
      service.getDailyReport().then(response => {
        let numItems = response.todayNum
        let report = JSON.parse(response.todayObj)
        let lineAmounts = this.calculateByLine(report.data);
        let skuAmounts = this.calculateBySku(report.data);
        let numItemsYesterday = response.yesterdayNum
        let reportYesterday = JSON.parse(response.yesterdayObj)
        let lineAmountsYesterday = this.calculateByLine(reportYesterday.data);
        let skuAmountsYesterday = this.calculateBySku(reportYesterday.data);
        this.setState({ 
          spin: false,
          dailyNum: numItems,
          dailyReport: report,
          lines: lineAmounts,
          skus: skuAmounts,
          dailyNumYesterday: numItemsYesterday,
          dailyReportYesterday: reportYesterday,
          linesYesterday: lineAmountsYesterday,
          skusYesterday: skuAmountsYesterday,
        });
      }).catch(err => {
        console.log(err);
      });
    }
    
  }

  calculateBySku = (rows) => {
    let skuObj = {};
    rows.map((row, index) => {
      let sku = row[1];
      if (skuObj[sku]) {
        skuObj[sku] += row[2];
      } else {
        skuObj[sku] = row[2];
      }
    })
    let keys = Object.keys(skuObj);
    let totals = [];
    for (let i=0; i < keys.length; i++) {
      let currKey = keys[i];
      let currAmount = skuObj[currKey];
      totals.push([currKey, currAmount]);
    }
    return totals;
  }

  calculateByLine = (rows) => {
    let lineObj = {};
    rows.map((row, index) => {
      let line = lineConverter[row[1]];
      if (lineObj[line]) {
        lineObj[line] += row[2];
      } else {
        lineObj[line] = row[2];
      }
    })
    return lineObj;
  }

  refresh = () => {
    this.componentDidMount();
    return;
  }

  render() {
    return (      
      <div className="shadow-lg ml-0.5 mt-1 px-4 py-6 w-full bg-gray-800 relative grow">
        <span className="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          Daily Report (Today) <Modal data={this.state.dailyReport} numCols={3} art={true} /> <SkuModal cols={['Sku', 'Total']} data={this.state.skus} />
        </span>
        <button onClick={this.refresh} className="float-right text-md text-white">
          <IoIosRefresh className="hover:text-green-700" />
        </button>
        <div className="flex items-end space-x-2 my-4">
          {this.state.spin ? 
            <svg role="status" className="inline w-8 h-8 mr-2 text-gray-200 float-center animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg> : null }
          <p className="text-5xl text-white dark:text-white font-bold">
            {this.state.dailyNum}
          </p>
        </div>
        <div className="flex">
          <p className="text-sm text-white dark:text-white font-bold">
            {`Mugs: ${this.state.lines['Mug'] ? this.state.lines['Mug'] : 0} 
            Posters: ${this.state.lines['Poster'] ? this.state.lines['Poster'] : 0} 
            Press: ${this.state.lines['Press'] ? this.state.lines['Press'] : 0}
            Other: ${this.state.dailyNum - ((this.state.lines['Press'] ? this.state.lines['Press'] : 0) + (this.state.lines['Poster'] ? this.state.lines['Poster'] : 0) + (this.state.lines['Mug'] ? this.state.lines['Mug'] : 0)) }`}
          </p>
        </div>
        <hr className="mb-5" />
        <span className="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          Daily Report (Yesterday) <Modal data={this.state.dailyReportYesterday} numCols={3} art={true} /> <SkuModal cols={['Sku', 'Total']} data={this.state.skusYesterday} />
        </span>
        <div className="flex items-end space-x-2 my-3">
          {this.state.spin ? 
            <svg role="status" className="inline w-8 h-8 mr-2 text-gray-200 float-center animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg> : null }
          <p className="text-5xl text-white dark:text-white font-bold">
            {this.state.dailyNumYesterday}
          </p>
        </div>
        <div className="flex">
          <p className="text-sm text-white dark:text-white font-bold">
            {`Mugs: ${this.state.linesYesterday['Mug'] ? this.state.linesYesterday['Mug'] : 0} 
            Posters: ${this.state.linesYesterday['Poster'] ? this.state.linesYesterday['Poster'] : 0} 
            Press: ${this.state.linesYesterday['Press'] ? this.state.linesYesterday['Press'] : 0}
            Other: ${this.state.dailyNumYesterday - ((this.state.linesYesterday['Press'] ? this.state.linesYesterday['Press'] : 0) + (this.state.linesYesterday['Poster'] ? this.state.linesYesterday['Poster'] : 0) + (this.state.linesYesterday['Mug'] ? this.state.linesYesterday['Mug'] : 0)) }`}
          </p>
        </div>
      </div>
    )
  }
}

export default DailyReport;