import axios from "axios";
import * as env from "../config/env";
const baseUrl = `${env.url}/stats/gr`;
const paperUrl = `https://stage.printversepro.com/printers/api`
//https://stage.printversepro.com/printers/api/reload/{pc_name}/{printer_name}

const headers = {
  "User-Token": env.userToken,
};

export function resetPaper(data) {
  const config = {
    method: "POST",
    //headers,
    data: data
  };
  return axios.post(`${paperUrl}/reload`, config).then(responseSuccessHandler).catch(responseErrorHandler);
  //return axios.post(`${baseUrl}/resetPaper`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getPaperStatus(data) {
  const config = {
    method: "GET",
    //headers
  };
  //console.log(data.pc_name);
  //console.log(data.name);
  return axios.get(`${paperUrl}/dash/${data.pc_name}/${data.name}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

const responseSuccessHandler = response => {
  return response.data;
};
    
const responseErrorHandler = error => {
  console.log(error);
  return Promise.reject(error);
};