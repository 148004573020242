import React from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as orderStatsService from "../services/orderStats.service";

class RefundInfoCard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      refundsAll: null,
      refundsYear: null,
      refundsLastYear: null,
      refundsMonth: null,
      refundsWeek: null
    }
  }
  componentDidMount() {
    orderStatsService.getRefundData().then(response => {
      //console.log("REFUND DATA");
      //console.log(response);
      let allRefunds = response.num.all;
      let yearRefunds = response.num.year;
      let lastYearRefunds = response.num.lastYear;
      let monthRefunds = response.num.month;
      let weekRefunds = response.num.week;
      this.setState({
        refundsAll: allRefunds,
        refundsYear: yearRefunds,
        refundsLastYear: lastYearRefunds,
        refundsMonth: monthRefunds,
        refundsWeek: weekRefunds
      })
    }).catch(err => {
      console.log(err)
    })
  }
  render() {
    return (      
      <div class="shadow-lg ml-0.5 mt-1 px-4 py-6 w-full bg-slate-700 dark:bg-gray-800 relative">
        <p class="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
          Refunds This Week
        </p>
        {/* <p class="flex text-sm items-end text-white dark:text-white font-semibold">
          Sku : Quantity
        </p> */}
        <div class="flex items-end space-x-2 my-6">
          <p class="text-5xl text-white dark:text-white font-bold">
            {this.state.refundsWeek}
          </p>
          {/* <span class="text-green-500 text-xl font-bold flex items-center">
            <svg width="20" fill="currentColor" height="20" class="h-3" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
              <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
              </path>
            </svg>
            22%
          </span> */}
        </div>
        <div class="text-white">
          <div class="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <p>
              This Month  
            </p>
            <div class="flex items-end text-xs">
              {/* {this.props.today ? `${this.props.today[0] ? this.props.today[0] : 'N/A : '}` : 'N/A : '} */}
              <span class="flex items-center">
                {/* <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                  </path>
                </svg> */}
                {/* {this.props.today ? this.props.today[1] : ' N/A'} */}
                {this.state.refundsMonth}
              </span>
            </div>
          </div>
          <div class="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <p>
              This Year  
            </p>
            <div class="flex items-end text-xs">
              {/* {this.props.week ? `${this.props.week[0] ? this.props.week[0] : 'N/A : '}` : 'N/A : '} */}
              <span class="flex items-center">
                {/* <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                  </path>
                </svg> */}
                {/* {this.props.week ? this.props.week[1] : ' N/A'} */}
                {this.state.refundsYear}
              </span>
            </div>
          </div>
          <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <p>
              Last Year
            </p>
            <div class="flex items-end text-xs">
              {/* {this.props.month ? `${this.props.month[0] ? this.props.month[0] : 'N/A : '}` : 'N/A : '} */}
              <span class="flex items-center">
                {/* <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                  </path>
                </svg> */}
                {/* {this.props.month ? this.props.month[1] : ' N/A'} */}
                {this.state.refundsLastYear}
              </span>
            </div>
          </div>
          {/* <div class="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
            <p>
              All Time
            </p>
            <div class="flex items-end text-xs">
              {{this.props.year ? `${this.props.year[0] ? this.props.year[0] : 'N/A : '} : ` : 'N/A : '} }
              <span class="flex items-center">
                {<svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                  </path>
                </svg> }
                {{this.props.year ? ` ${this.props.year[1]}` : ' N/A'}}
                {this.state.refundsAll}
              </span>
            </div>
          </div> */}

          <div class="flex items-center text-sm space-x-14 md:space-x-26 justify-between">
            <p>
              All Time
            </p>
            <div class="flex items-end text-xs">
              {/* {this.props.all ? `${this.props.all[0] ? this.props.all[0] : 'N/A : '} : ` : 'N/A : '} */}
              <span class="flex items-center">
                {/* <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                  </path>
                </svg> */}
                {/* {this.props.all ? ` ${this.props.all[1]}` : ' N/A'} */}
                {this.state.refundsAll}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RefundInfoCard;