import axios from "axios";
import * as env from "../config/env";
const baseUrl = `${env.url}/stats`;
// const baseUrl = 'https://services.printversepro.com/stats';
const headers = {
  "User-Token": env.userToken,
};

export function getOrders() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/orders`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getPopSkus() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/skus`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getPrelimLines(timeFrame) {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/lines?time=${timeFrame}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getRefundData() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/refunds`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getShippingData(timeFrame) {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/shipping?time=${timeFrame}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getSkuBars() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/skuBars`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getOrderCycle() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/cycle`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getPopularLocation(timeFrame, location) {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(`${baseUrl}/locations?time=${timeFrame}&location=${location}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getOrdersByTimeFrame(timeframe) {
  const config = {
    method: "POST",
    headers
  }
  return axios.post(`${baseUrl}/orders`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

const responseSuccessHandler = response => {
  return response.data;
};

const responseErrorHandler = error => {
  console.log(error);
  return Promise.reject(error);
};