import React from "react";
import Modal from "./Modal";
import * as opOrderStatsService from "../services/openprintStats.service";
import { IoIosRefresh } from "react-icons/io";

class OpRevenue extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        
      }
    }
  
    componentDidMount() {
      opOrderStatsService.getRevenue("year").then(response => {
        console.log(response);
        
        // this.setState({ 
          
        // });
      }).catch(err => {
        console.log(err);
      })
    }

    refresh = () => {
      this.componentDidMount();
      return;
    }
  
    render() {
      return (      
        <div class="shadow-lg ml-0.5 mt-1 px-4 py-6 w-full bg-gray-800 relative grow">
          <span class="text-sm w-max text-white dark:text-white font-semibold border-b border-gray-200">
            Revenue <Modal data={this.state.printedObj} numCols={2} />
          </span>
          <button onClick={this.refresh} className="float-right text-md text-white">
            <IoIosRefresh className="hover:text-green-700" />
          </button>
          <div class="flex items-end space-x-2 my-6">
            <p class="text-5xl text-white dark:text-white font-bold">
              {this.state.ready}
            </p>
          </div>
          <div class="text-white">
            <div class="flex items-center pb-2 mb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
              <span>
                Total Orders <Modal data={this.state.entireObj} numCols={2} />
              </span>
              <div class="flex items-end text-xs">
                {this.state.totalInQueue}
              </div>
            </div>
            <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
              <span>
                Newly Created <Modal data={this.state.needsCompositeObj} numCols={2} />
              </span>
              <div class="flex items-end text-xs">
                {this.state.notComposited}
              </div>
            </div>
            <div class="flex items-center mb-2 pb-2 text-sm space-x-12 md:space-x-24 justify-between border-b border-gray-200">
              <span>
                Composite Queue <Modal data={this.state.needsDownloadObj} numCols={2} />
              </span>
              <div class="flex items-end text-xs">
                {this.state.notDownloaded}
              </div>
            </div>
            <div class="flex items-center text-sm space-x-12 md:space-x-24 justify-between">
              <span>
                Not Printed <Modal data={this.state.downloadedObj} numCols={2} />
              </span>
            <div class="flex items-end text-xs">
              {this.state.notPrinted}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
  
export default OpRevenue;