import React from "react";
import { Navigate } from "react-router-dom";
import * as LoginService from '../services/login.service';
import Cookies from 'js-cookie'

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      error: null
    }
  }

  componentDidMount() {
    //console.log(store);
    //let currEmail;
    // if (currEmail) {
    //   this.setState({
    //     user: null
    //   })
    // }
    let currEmail = localStorage.getItem('UserEmail');
    //console.log("CURR EMAIL");
    //console.log(currEmail);
    let cookie = Cookies.get('token')
    //setTimeout(() => {console.log("TIME")}, 5000)
    if (currEmail && cookie) {
      this.setState({
        user: currEmail
      })
    }
  }
  
  handleFormSubmit = (e) => {
    e.preventDefault();

    if (this.state.error) {
      this.setState({
        error: null,
      });
    };
    let email = e.target.elements.email?.value;
    let password = e.target.elements.password?.value;
    //console.log(email)
    //console.log(password);
    if (email === ""){
      this.setState({
        error: "You Must Provide an Email"
      });
    } else if (password === "") {
      this.setState({
        error: "You Must Provide a Password"
      });
    } else {
      let data = {
        "email": email,
        "pass": password,
      } 

      LoginService.getLogin(data).then(response => {
        console.log(response);
        localStorage.setItem('UserEmail', email);
        let role = response.role;
        localStorage.setItem('Role', role);
        let token = Math.random().toString(36);
        //console.log(token);
        Cookies.set('token', token, {expires: 1/2})
        this.setState({
          user: email,
          role: role
        });
      }).catch(err => {
        
        console.log(err);
        this.setState({
          error: "Your Email/Password is Incorrect"
        })
      });
    }
    
    // let data = {
    //   "email": email,
    //   "pass": password,
    //   "role": "graphics"
    // } 
    // LoginService.saveLogin(data).then(response => {
    //   console.log(response);
    // }).catch(err => {
    //   console.log(err);
    // })

    // console.log("NAVIGATOR");

    //console.log(this.props.navigator);
    //console.log(this.props.navigator.navigate);

    //this.props.navigator.push("printverse")
    //navigate("../printverse", { replace: true });

    //this.navigate("../printverse", { replace: true });

    //console.log(email, password);

  };
  render() {
    return (
      <div className="h-screen flex bg-gray-800">
        
        
        {(this.state.user && (this.state.role === 'admin')) && (
          //false
          <Navigate to="/printverse" replace={true} />
        )}
        {(this.state.user && this.state.role) && (
          //false
          <Navigate to="/openprint" replace={true} />
        )}
        {(this.state.user && (this.state.role === 'ky prod')) && (
          //false
          <Navigate to="/kentuckyop" replace={true} />
        )}
        <div className='w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-16'>
          {this.state.error && (
            <h3 className="text-red-500 text-xs italic">{this.state.error}</h3>
          )}
          <h1 className='text-2xl font-medium text-primary mt-4 mb-12 text-center'>
            PhotoUSA Admin Data Dashboard 🔐
          </h1>
          <form onSubmit={this.handleFormSubmit}>
            <div>
              <label htmlFor='email'>Email</label>
              <input
                type='email'
                id='email'
                className='w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4'
                placeholder='Email'
              />
            </div>
            <div>
              <label htmlFor='password'>Password</label>
              <input
                type='password'
                id='password'
                className='w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4'
                placeholder='Password'
              />
            </div>

            <div className='flex justify-center items-center mt-6'>
              <button type="submit" className='bg-gray-800 py-2 px-4 text-sm text-white rounded border border-green focus:outline-none focus:border-green-dark'>
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
